<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

<script setup lang="ts">
import { JWT_ACCESS_TOKEN, USER_INFO } from '~/utils/constants'
import { useStore } from 'vuex'

const THREE_MINUTES = 3 * 60 * 1000
const { $backendVersionWatcher, $cookies, $membershipApi } = useNuxtApp()
const store = useStore()

onMounted(() => {
  $membershipApi.restoreMemberships()
  $backendVersionWatcher.invalidateSession()
  setInterval(() => {
    $backendVersionWatcher.invalidateSession()
  }, THREE_MINUTES)
})

onBeforeMount(() => {
  const jwtToken = $cookies.get(JWT_ACCESS_TOKEN)
  const user = $cookies.get(USER_INFO)
  store.commit('auth/setJwtToken', jwtToken)
  store.commit('auth/setUser', user)
  if (!jwtToken) store.commit('memberships/clearKeys')
})
</script>
