import * as Sentry from '@sentry/vue'
import { useRuntimeConfig } from '#imports'

export default defineNuxtPlugin(({ $router, vueApp }) => {
  const sentryDsn = useRuntimeConfig().public.SENTRY_DSN
  if (sentryDsn) {
    Sentry.init({
      app: vueApp,
      dsn: sentryDsn,
      environment: useRuntimeConfig().public.SENTRY_ENVIRONMENT,
      integrations: [
        Sentry.browserTracingIntegration({
          router: $router,
        }),
        Sentry.replayIntegration({
          maskAllText: false,
          maskAllInputs: false,
          blockAllMedia: false,
        }),
        Sentry.replayCanvasIntegration(),
      ],
      trackComponents: true,
      sampleRate: 1,
      tracesSampleRate: 1,
      replaysSessionSampleRate: 0,
      replaysOnErrorSampleRate: 1,
    })
  }
})
