const bwo21 = {
  'purple-300': '#722DB9',
  'purple-500': '#410E76',
  'purple-700': '#310A59',
  'purple-800': '#241B40',
  'yellow-100': '#FFF2CE',
  'gray-800': '#302C2A',
}

export default bwo21
