import { defineStore } from 'pinia'

const useIndexesStore = defineStore('indexes', () => {
  const indexes = ref([])

  const setIndexesStates = (newIndexes: any[]) => {
    indexes.value = newIndexes.map((index) => ({
      name: index.name,
      ...index.values,
    }))
  }

  return { indexes, setIndexesStates }
})

export default useIndexesStore
