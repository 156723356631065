export const state = () => ({
  page: '',
  position: 0,
})

export const mutations = {
  setScrollPosition(state, position) {
    state.position = position
  },
  setPage(state, page) {
    state.page = page
  },
}

export const actions = {
  setScrollPosition({ commit }, position) {
    commit('setScrollPosition', position)
  },
  setPage({ commit }, page) {
    commit('setPage', page)
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
