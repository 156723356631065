import upperFirst from 'lodash/upperFirst'
import { AVAILABLE_SERVICES } from '~/constants/services/constants'

/**
 * @param {string} slug
 * @param {string} authorizedRoute
 * @param {boolean} showChart
 * @param {string|null} _section
 * @returns {object}
 */
export const getRoutes = (
  slug,
  authorizedRoute = 'portal',
  showChart = true,
  _section = null,
) => {
  const defaultRoute = getRoute(slug, _section)
  const authorized = `${defaultRoute}/${authorizedRoute}`
  const routes = {
    default: defaultRoute,
    authorized,
  }
  if (showChart) {
    return { ...routes, chart: `${defaultRoute}/chart` }
  }
  return routes
}

export const getRoute = (slug, _section = null) => {
  return _section ? `/${slug}/${_section}` : `/${slug}`
}

export const getRouteByOverview = (market, slug, name, _parent) => [
  {
    to: _parent ? `/${_parent}/${market}/${slug}` : `/${market}/${slug}`,
    name,
  },
]

export const getMenu = (
  name,
  slug,
  _section = null,
  hasChildren = true,
  firstItem = '',
) => {
  return {
    name,
    href: `/${slug}`,
    parent: _section ? `/${_section}` : '',
    hasChildren,
    firstItem: firstItem.replaceAll(' ', '-'),
  }
}

export const getSidebar = (
  slug,
  name,
  icon,
  children,
  isPrivate = false,
  _section = null,
) => {
  const sectionName = AVAILABLE_SERVICES.find((item) => item.slug === _section)

  return {
    name: slug,
    section: sectionName?.name,
    label: name,
    icon,
    to: getRoute(slug, _section),
    open: false,
    children: getChildrenSidebar(children, getRoute(slug, _section)),
    isPrivate,
  }
}

export const getChildrenSidebar = (children, path) => {
  return (
    children?.map((_item) => {
      return {
        name: upperFirst(_item.name),
        to: _item.externalLink ? _item.path : `${path}/${_item.path}`,
        page: _item.path,
        externalLink: _item.externalLink,
        icon: _item.icon,
      }
    }) ?? []
  )
}
