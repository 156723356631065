import { intervalToDuration } from 'date-fns'

const zeroPad = (number: number) => ('0' + number).slice(-2)

export const formatVideoDuration = (_seconds: number): string => {
  const {
    hours = 0,
    minutes = 0,
    seconds = 0,
  } = intervalToDuration({ start: 0, end: _seconds * 1000 })

  const formattedMinutes = zeroPad(minutes)
  const formattedSeconds = zeroPad(seconds)

  if (hours > 0) {
    return `${hours}:${formattedMinutes}:${formattedSeconds}`
  }

  if (minutes > 0) {
    return `${minutes}:${formattedSeconds}`
  }

  return `0:${formattedSeconds}`
}
