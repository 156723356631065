import validate from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_eslint@9.9.0_jiti@1.21.6__ioredis@5.4.1__k6vovhhwwg4xngea5tlujjkh5i/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/opt/buildhome/repo/middleware/auth.global.ts";
import redirects_45global from "/opt/buildhome/repo/middleware/redirects.global.ts";
import ticker_45global from "/opt/buildhome/repo/middleware/ticker.global.ts";
import trailing_45slash_45global from "/opt/buildhome/repo/middleware/trailing-slash.global.ts";
import manifest_45route_45rule from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_eslint@9.9.0_jiti@1.21.6__ioredis@5.4.1__k6vovhhwwg4xngea5tlujjkh5i/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  redirects_45global,
  ticker_45global,
  trailing_45slash_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  authenticated: () => import("/opt/buildhome/repo/middleware/authenticated.ts"),
  "has-active-membership": () => import("/opt/buildhome/repo/middleware/has-active-membership.ts"),
  "refresh-live-chat": () => import("/opt/buildhome/repo/middleware/refresh-live-chat.ts")
}