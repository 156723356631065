export const loadLiveChat = ({ email, token }) => {
  window.hsConversationsSettings = {
    loadImmediately: false,
    identificationEmail: email,
    identificationToken: token,
  }

  if (window.HubSpotConversations) {
    window.HubSpotConversations.widget.load()
  } else {
    window.hsConversationsOnReady = [
      () => {
        window.HubSpotConversations.widget.load()
      },
    ]
  }
}

export const openLiveChat = () => {
  window.HubSpotConversations.widget.open()
}

export const removeLiveChat = () => {
  window.HubSpotConversations.widget.remove()
  window.HubSpotConversations.clear({ resetWidget: true })
}

export const refreshLiveChat = () => {
  if (!window.HubSpotConversations?.widget) return

  const status = window.HubSpotConversations.widget.status()

  if (status.loaded) {
    window.HubSpotConversations.widget.close()
    window.HubSpotConversations.widget.refresh()
  } else {
    window.HubSpotConversations.widget.load()
  }
}
