import {
  checkAllPredefinedOptions,
  createDynamicFilter,
  generateCombinations,
  sortOptionsAlphabetically,
} from '~/utils/chart'

export const state = () => ({
  catalog: {},
  preselected: {
    lithium: [
      'Lithium Carbonate',
      'Lithium Hydroxide',
      'Min 55.0%',
      'Min 99.0%',
      'Global Weighted Average',
    ],
    cobalt: [
      'Cobalt Hydroxide',
      'Cobalt Metal',
      'Cobalt Oxide',
      'Cobalt Sulphate',
      'Min 20%',
      'Min 20.5%',
      'Min 72.8%',
      'Min 99.8%',
      'CIF Asia',
      'EXW China',
      'EXW Europe',
    ],
    nickel: [
      'Nickel Metal',
      'Nickel Sulphate',
      '100% Ni Contained',
      'Min 22%',
      'Min 99.8%',
      'CIF Asia',
      'CIF Asia (Normalised)',
      'EXW China',
      'EXW Europe',
    ],
    'natural-graphite': [
      'Flake',
      'Spherical Uncoated',
      '-100 mesh',
      '15 microns',
      '94-95% C',
      'DDP China',
      'FOB China',
      'CIF N America',
    ],
    'synthetic-graphite': [
      'Pre-calcined Pet Coke',
      'Pre-calcined Pet Needle Coke',
      'Pre-calcined Pitch Needle Coke',
      'Tolling Fees',
      'S<0.5%',
      '0.5%<S<3.0%',
      'C≥90%',
      'DDP China',
    ],
    anode: [
      'Natural Graphite Anode',
      'Synthetic Graphite Anode',
      'High (360-370 mAh/g), min 99.97% C',
      'High (350-370 mAh/g), min 99.97% C',
      'DDP China',
    ],
    cathode: [
      'LFP, ≥160 mAh/g',
      'NCM523, 150-170 mAh/g',
      'NCM613, 170-200 mAh/g',
      'NCM622, 155-180 mAh/g',
      'DDP China',
    ],
    'lithium-ion-batteries': [
      'NCM111',
      'NCM523',
      'NCM622',
      'NCM811',
      'NCA',
      'LFP',
      'LCO',
      'Global Weighted Average',
      'Pouch',
      'Prismatic',
      'Cylindrical',
      'EXW Asia',
      'EXW China',
      'EXW Europe',
      'EXW North America',
    ],
    'black-mass': [
      'All Chemistries',
      'LCO',
      'LFP',
      'NCM',
      'All Scrap',
      'Cobalt Metal',
      'Cobalt Sulphate',
      'End of Life',
      'Lithium Carbonate Equivalent',
      'Li Price per Percentage Point',
      'Nickel Metal',
      'Nickel Sulphate',
      'Process Scrap',
      'CIF Asia',
      'EXW China',
      'EXW North America',
    ],
    'rare-earths': [
      'Dysprosium Oxide',
      'Neodymium-Praseodymium Oxide',
      'Samarium Oxide',
      'Terbium Oxide',
      'China Weighted Average',
      '>22%>70%',
      '99.0-99.5%',
      '99.0-99.9%',
      'DDP China',
    ],
  },
  preselectedCombinations: {
    lithium: [
      {
        category: 'Lithium Carbonate',
        purity: 'Min 99.0%',
      },
      {
        category: 'Lithium Hydroxide',
        purity: 'Min 55.0%',
      },
    ],
    cobalt: [
      {
        category: 'Cobalt Hydroxide',
        purity: 'Min 20%',
        shippingRoute: 'CIF Asia',
      },
      {
        category: 'Cobalt Metal',
        purity: 'Min 99.8%',
        shippingRoute: 'EXW Europe',
      },
      {
        category: 'Cobalt Oxide',
        purity: 'Min 72.8%',
        shippingRoute: 'EXW China',
      },
      {
        category: 'Cobalt Sulphate',
        purity: 'Min 20.5%',
        shippingRoute: 'EXW China',
      },
    ],
    nickel: [
      {
        category: 'Nickel Sulphate',
        purity: '100% Ni Contained',
        shippingRoute: 'CIF Asia',
      },
      {
        category: 'Nickel Sulphate',
        purity: 'Min 22%',
        shippingRoute: 'EXW China',
      },
    ],
    'natural-graphite': [
      {
        category: 'Flake',
        meshSize: '-100 mesh',
        purity: '94-95% C',
        shippingRoute: 'CIF N America',
      },
      {
        category: 'Flake',
        meshSize: '-100 mesh',
        purity: '94-95% C',
        shippingRoute: 'DDP China',
      },
      {
        category: 'Flake',
        meshSize: '-100 mesh',
        purity: '94-95% C',
        shippingRoute: 'FOB China',
      },
    ],
    'synthetic-graphite': [
      {
        category: 'Pre-calcined Pet Coke',
        purity: 'S<0.5%',
        shippingRoute: 'DDP China',
      },
      {
        category: 'Pre-calcined Pet Needle Coke',
        purity: 'S<0.5%',
        shippingRoute: 'DDP China',
      },
      {
        category: 'Pre-calcined Pitch Needle Coke',
        purity: 'S<0.5%',
        shippingRoute: 'DDP China',
      },
    ],
    anode: [
      {
        category: 'Natural Graphite Anode',
        purity: 'High (360-370 mAh/g), min 99.97% C',
        shippingRoute: 'DDP China',
      },
      {
        category: 'Synthetic Graphite Anode',
        purity: 'High (350-370 mAh/g), min 99.97% C',
        shippingRoute: 'DDP China',
      },
    ],
    cathode: [
      {
        category: 'LFP, ≥160 mAh/g',
        shippingRoute: 'DDP China',
      },
      {
        category: 'NCM523, 150-170 mAh/g',
        shippingRoute: 'DDP China',
      },
      {
        category: 'NCM613, 170-200 mAh/g',
        shippingRoute: 'DDP China',
      },
      {
        category: 'NCM622, 155-180 mAh/g',
        shippingRoute: 'DDP China',
      },
    ],
    'lithium-ion-batteries': [
      {
        category: 'LFP',
        format: 'Cylindrical',
        shippingRoute: 'EXW Asia',
      },
      {
        category: 'LFP',
        format: 'Prismatic',
        shippingRoute: 'EXW China',
      },
      {
        category: 'NCA',
        format: 'Cylindrical',
        shippingRoute: 'EXW Asia',
      },
      {
        category: 'NCA',
        format: 'Cylindrical',
        shippingRoute: 'EXW North America',
      },
      {
        category: 'NCA',
        format: 'Pouch',
        shippingRoute: 'EXW Asia',
      },
      {
        category: 'NCA',
        format: 'Pouch',
        shippingRoute: 'EXW China',
      },
      {
        category: 'NCA',
        format: 'Pouch',
        shippingRoute: 'EXW Europe',
      },
      {
        category: 'NCA',
        format: 'Pouch',
        shippingRoute: 'EXW North America',
      },
      {
        category: 'NCA',
        format: 'Prismatic',
        shippingRoute: 'EXW Asia',
      },
      {
        category: 'NCA',
        format: 'Prismatic',
        shippingRoute: 'EXW China',
      },
      {
        category: 'NCA',
        format: 'Prismatic',
        shippingRoute: 'EXW Europe',
      },
      {
        category: 'NCA',
        format: 'Prismatic',
        shippingRoute: 'EXW North America',
      },
      {
        category: 'NCM111',
        format: 'Pouch',
        shippingRoute: 'EXW Asia',
      },
      {
        category: 'NCM111',
        format: 'Pouch',
        shippingRoute: 'EXW China',
      },
      {
        category: 'NCM111',
        format: 'Pouch',
        shippingRoute: 'EXW Europe',
      },
      {
        category: 'NCM111',
        format: 'Pouch',
        shippingRoute: 'EXW North America',
      },
      {
        category: 'NCM111',
        format: 'Prismatic',
        shippingRoute: 'EXW Asia',
      },
      {
        category: 'NCM111',
        format: 'Prismatic',
        shippingRoute: 'EXW China',
      },
      {
        category: 'NCM111',
        format: 'Prismatic',
        shippingRoute: 'EXW Europe',
      },
      {
        category: 'NCM111',
        format: 'Prismatic',
        shippingRoute: 'EXW North America',
      },
      {
        category: 'NCM523',
        format: 'Pouch',
        shippingRoute: 'EXW Asia',
      },
      {
        category: 'NCM523',
        format: 'Pouch',
        shippingRoute: 'EXW China',
      },
      {
        category: 'NCM523',
        format: 'Pouch',
        shippingRoute: 'EXW Europe',
      },
      {
        category: 'NCM523',
        format: 'Pouch',
        shippingRoute: 'EXW North America',
      },
      {
        category: 'NCM523',
        format: 'Prismatic',
        shippingRoute: 'EXW Asia',
      },
      {
        category: 'NCM523',
        format: 'Prismatic',
        shippingRoute: 'EXW China',
      },
      {
        category: 'NCM523',
        format: 'Prismatic',
        shippingRoute: 'EXW Europe',
      },
      {
        category: 'NCM523',
        format: 'Prismatic',
        shippingRoute: 'EXW North America',
      },
      {
        category: 'NCM622',
        format: 'Pouch',
        shippingRoute: 'EXW Asia',
      },
      {
        category: 'NCM622',
        format: 'Pouch',
        shippingRoute: 'EXW China',
      },
      {
        category: 'NCM622',
        format: 'Pouch',
        shippingRoute: 'EXW Europe',
      },
      {
        category: 'NCM622',
        format: 'Pouch',
        shippingRoute: 'EXW North America',
      },
      {
        category: 'NCM622',
        format: 'Prismatic',
        shippingRoute: 'EXW Asia',
      },
      {
        category: 'NCM622',
        format: 'Prismatic',
        shippingRoute: 'EXW China',
      },
      {
        category: 'NCM622',
        format: 'Prismatic',
        shippingRoute: 'EXW Europe',
      },
      {
        category: 'NCM622',
        format: 'Prismatic',
        shippingRoute: 'EXW North America',
      },
      {
        category: 'NCM811',
        format: 'Cylindrical',
        shippingRoute: 'EXW Asia',
      },
      {
        category: 'NCM811',
        format: 'Cylindrical',
        shippingRoute: 'EXW China',
      },
      {
        category: 'NCM811',
        format: 'Cylindrical',
        shippingRoute: 'EXW Europe',
      },
      {
        category: 'NCM811',
        format: 'Cylindrical',
        shippingRoute: 'EXW North America',
      },
      {
        category: 'NCM811',
        format: 'Pouch',
        shippingRoute: 'EXW Asia',
      },
      {
        category: 'NCM811',
        format: 'Pouch',
        shippingRoute: 'EXW China',
      },
      {
        category: 'NCM811',
        format: 'Pouch',
        shippingRoute: 'EXW Europe',
      },
      {
        category: 'NCM811',
        format: 'Pouch',
        shippingRoute: 'EXW North America',
      },
      {
        category: 'NCM811',
        format: 'Prismatic',
        shippingRoute: 'EXW Asia',
      },
      {
        category: 'NCM811',
        format: 'Prismatic',
        shippingRoute: 'EXW China',
      },
      {
        category: 'NCM811',
        format: 'Prismatic',
        shippingRoute: 'EXW Europe',
      },
      {
        category: 'NCM811',
        format: 'Prismatic',
        shippingRoute: 'EXW North America',
      },
    ],
    'black-mass': [
      {
        feedstock: 'All Chemistries',
        product: 'All Scrap',
        shippingRoute: 'EXW North America',
      },
      {
        feedstock: 'All Chemistries',
        product: 'EOL',
        shippingRoute: 'CIF Asia',
      },
      {
        feedstock: 'All Chemistries',
        product: 'EOL',
        shippingRoute: 'EXW China',
      },
      {
        feedstock: 'All Chemistries',
        product: 'Process Scrap',
        shippingRoute: 'CIF Asia',
      },
      {
        feedstock: 'All Chemistries',
        product: 'Process Scrap',
        shippingRoute: 'EXW China',
      },
      {
        feedstock: 'LCO',
        product: 'All Scrap',
        shippingRoute: 'CIF Asia',
      },
      {
        feedstock: 'LCO',
        product: 'All Scrap',
        shippingRoute: 'EXW China',
      },
      {
        feedstock: 'LCO',
        product: 'CoSO4',
        shippingRoute: 'EXW China',
      },
      {
        feedstock: 'LCO',
        product: 'LCE',
        shippingRoute: 'EXW China',
      },
      {
        feedstock: 'LCO',
        product: 'Co',
        shippingRoute: 'CIF Asia',
      },
      {
        feedstock: 'LCO',
        product: 'Co',
        shippingRoute: 'EXW North America',
      },
      {
        feedstock: 'LCO',
        product: 'CoSO4',
        shippingRoute: 'EXW China',
      },
      {
        feedstock: 'LCO',
        product: 'LCE',
        shippingRoute: 'EXW China',
      },
      {
        feedstock: 'LFP',
        product: 'All Scrap',
        shippingRoute: 'EXW China',
      },
      {
        feedstock: 'LFP',
        product: 'Li Price per Percentage Point',
        shippingRoute: 'EXW China',
      },
      {
        feedstock: 'NCM',
        product: 'All Scrap',
        shippingRoute: 'CIF Asia',
      },
      {
        feedstock: 'NCM',
        product: 'All Scrap',
        shippingRoute: 'EXW China',
      },
      {
        feedstock: 'NCM',
        product: 'CoSO4',
        shippingRoute: 'EXW China',
      },
      {
        feedstock: 'NCM',
        product: 'LCE',
        shippingRoute: 'EXW China',
      },
      {
        feedstock: 'NCM',
        product: 'NiSO4',
        shippingRoute: 'EXW China',
      },
      {
        feedstock: 'NCM',
        product: 'Co',
        shippingRoute: 'CIF Asia',
      },
      {
        feedstock: 'NCM',
        product: 'Co',
        shippingRoute: 'EXW North America',
      },
      {
        feedstock: 'NCM',
        product: 'CoSO4',
        shippingRoute: 'EXW China',
      },
      {
        feedstock: 'NCM',
        product: 'LCE',
        shippingRoute: 'EXW China',
      },
      {
        feedstock: 'NCM',
        product: 'Ni',
        shippingRoute: 'CIF Asia',
      },
      {
        feedstock: 'NCM',
        product: 'Ni',
        shippingRoute: 'EXW North America',
      },
      {
        feedstock: 'NCM',
        product: 'NiSO4',
        shippingRoute: 'EXW China',
      },
    ],
    'rare-earths': [
      {
        category: 'China Weighted Average',
        shippingRoute: 'DDP China',
      },
      {
        category: 'Dysprosium Oxide',
        purity: '99.0-99.5%',
        shippingRoute: 'DDP China',
      },
      {
        category: 'Neodymium-Praseodymium Oxide',
        purity: '>22%>70%',
        shippingRoute: 'DDP China',
      },
      {
        category: 'Samarium Oxide',
        purity: '99.0-99.9%',
        shippingRoute: 'DDP China',
      },
      {
        category: 'Terbium Oxide',
        purity: '99.0-99.9%',
        shippingRoute: 'DDP China',
      },
    ],
  },
})

export const mutations = {
  setCatalogByMineral(state, { mineral, response }) {
    state.catalog[mineral] = {
      response,
    }
  },
}

export const actions = {
  fetchCatalogByMineral({ commit }, { mineral, response }) {
    commit('setCatalogByMineral', { mineral, response })
  },
}

export const getters = {
  getPricesCatalogByMineral: (state) => (market) => {
    const catalog = state.catalog[market]?.response
    const preselected = state.preselected[market]

    if (!catalog) return []

    const filters = createDynamicFilter(catalog, catalog.nextLevel, null, null)
    checkAllPredefinedOptions(filters, preselected)
    const sortedData = sortOptionsAlphabetically(filters)

    return Object.values(sortedData)
  },

  getAvailableCombinations: (state) => (mineral) => {
    const catalog = state.catalog[mineral]?.response

    if (!catalog) return []
    return generateCombinations(catalog)
  },
  getAvailablePreselectedCombinations: (state) => (mineral) =>
    state.preselectedCombinations[mineral],
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
