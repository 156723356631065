export default {
  '/membership/battery-supply-chain': 'https://source.benchmarkminerals.com/',
  '/membership/benchmark-we': 'https://source.benchmarkminerals.com/',
  '/membership/chinese-battery-megafactory-giant-catl-reaches-tier-1-status/)':
    'https://source.benchmarkminerals.com/',
  '/membership/china-controls-sway-of-electric-vehicle-power-through-battery-chemicals-cathode-and-anode-production':
    'https://source.benchmarkminerals.com/article/china-controls-sway-of-electric-vehicle-power-through-battery-chemicals-cathode-and-anode-production',
  '/data-visualisation':
    'https://source.benchmarkminerals.com/data-visualisation',
  '/downloads': 'https://source.benchmarkminerals.com/downloads/magazine',
  '/request-a-trial': 'https://source.benchmarkminerals.com/request-a-trial',
  '/video': 'https://source.benchmarkminerals.com/video/',
}
