import useIndexesStore from '~/stores/indexes'

export default defineNuxtRouteMiddleware(async () => {
  if (import.meta.server) return

  const { $restClient } = useNuxtApp()
  const indexesStore = useIndexesStore()
  const indexes = indexesStore.indexes

  if (indexes.length > 0) return

  $restClient
    .getTickerIndexes()
    .then((response) => {
      indexesStore.setIndexesStates(response)
    })
    .catch((error) => {
      logError(error)
    })
})
