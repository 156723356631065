export default {
  account: () => import("/opt/buildhome/repo/layouts/account.vue").then(m => m.default || m),
  article: () => import("/opt/buildhome/repo/layouts/article.vue").then(m => m.default || m),
  default: () => import("/opt/buildhome/repo/layouts/default.vue").then(m => m.default || m),
  error: () => import("/opt/buildhome/repo/layouts/error.vue").then(m => m.default || m),
  events: () => import("/opt/buildhome/repo/layouts/events.vue").then(m => m.default || m),
  "main-without-footer": () => import("/opt/buildhome/repo/layouts/main-without-footer.vue").then(m => m.default || m),
  overviews: () => import("/opt/buildhome/repo/layouts/overviews.vue").then(m => m.default || m),
  "page-bea": () => import("/opt/buildhome/repo/layouts/page-bea.vue").then(m => m.default || m),
  "price-assessments": () => import("/opt/buildhome/repo/layouts/price-assessments.vue").then(m => m.default || m),
  services: () => import("/opt/buildhome/repo/layouts/services.vue").then(m => m.default || m)
}