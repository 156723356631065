import revive_payload_client_MgmDv1xcjH from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_eslint@9.9.0_jiti@1.21.6__ioredis@5.4.1__k6vovhhwwg4xngea5tlujjkh5i/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_wrD5m4ceUU from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_eslint@9.9.0_jiti@1.21.6__ioredis@5.4.1__k6vovhhwwg4xngea5tlujjkh5i/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_VjjVEq5nO5 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_eslint@9.9.0_jiti@1.21.6__ioredis@5.4.1__k6vovhhwwg4xngea5tlujjkh5i/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_6jsMaX59ew from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-site-config@2.2.17_magicast@0.3.5_rollup@4.21.3_vite@5.4.5_@types+node@22.5.4_sass@1.78._w5x7zatq6ruwvlrdi4vvbj7ree/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import vuex_F8JCgj45hr from "/opt/buildhome/repo/plugins/vuex.ts";
import payload_client_OahDcTbMhh from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_eslint@9.9.0_jiti@1.21.6__ioredis@5.4.1__k6vovhhwwg4xngea5tlujjkh5i/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_M9tUDuA6En from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_eslint@9.9.0_jiti@1.21.6__ioredis@5.4.1__k6vovhhwwg4xngea5tlujjkh5i/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_GqvP5nF7PZ from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_eslint@9.9.0_jiti@1.21.6__ioredis@5.4.1__k6vovhhwwg4xngea5tlujjkh5i/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_095CCeYrDs from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_eslint@9.9.0_jiti@1.21.6__ioredis@5.4.1__k6vovhhwwg4xngea5tlujjkh5i/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_Ivz3jFeXOQ from "/opt/buildhome/repo/node_modules/.pnpm/@pinia+nuxt@0.5.4_magicast@0.3.5_rollup@4.21.3_typescript@5.6.2_vue@3.5.5_typescript@5.6.2__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_3T5zV1B31D from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_eslint@9.9.0_jiti@1.21.6__ioredis@5.4.1__k6vovhhwwg4xngea5tlujjkh5i/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_51x8EwGcHS from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-graphql-request@8.1.1_graphql@16.9.0_magicast@0.3.5_rollup@4.21.3_webpack-sources@3.2.3/node_modules/nuxt-graphql-request/dist/runtime/plugin.js";
import _01_axios_trfC49DPAq from "/opt/buildhome/repo/plugins/01.axios.ts";
import _01_cookies_rVe02PKG6J from "/opt/buildhome/repo/plugins/01.cookies.ts";
import _01_httpClient_h8ZAF6W6qM from "/opt/buildhome/repo/plugins/01.httpClient.ts";
import _02_rest_client_fJWj9SMMdq from "/opt/buildhome/repo/plugins/02.rest-client.ts";
import _02_userflow_client_D1CXvi5rkg from "/opt/buildhome/repo/plugins/02.userflow.client.ts";
import _03_hubspot_identity_client_ZVXR2lrEtt from "/opt/buildhome/repo/plugins/03.hubspot-identity.client.ts";
import _03_membershipApi_JTkBJU5CoA from "/opt/buildhome/repo/plugins/03.membershipApi.ts";
import _04_hubspot_live_chat_client_MYdZrEyANh from "/opt/buildhome/repo/plugins/04.hubspot-live-chat.client.ts";
import _05_bmAuth_VjB6OFhOHE from "/opt/buildhome/repo/plugins/05.bmAuth.ts";
import allPrices_Yj9VFMWy6I from "/opt/buildhome/repo/plugins/allPrices.ts";
import assets_client_MHsgPPxPuR from "/opt/buildhome/repo/plugins/assets.client.ts";
import browser_client_kZsmP6IGPw from "/opt/buildhome/repo/plugins/browser.client.ts";
import capacity_database_Ak8crLfnjm from "/opt/buildhome/repo/plugins/capacity-database.ts";
import chartjs_1wC9lAM0Tp from "/opt/buildhome/repo/plugins/chartjs.ts";
import cloudinary_SEKWrTzwvn from "/opt/buildhome/repo/plugins/cloudinary.ts";
import dateRangePicker_client_Hj8UQvsmQy from "/opt/buildhome/repo/plugins/dateRangePicker.client.ts";
import download_manager_gThQnK2QhW from "/opt/buildhome/repo/plugins/download-manager.ts";
import file_pond_client_bWiN9gXVf8 from "/opt/buildhome/repo/plugins/file-pond.client.ts";
import formidable_form_builder_hwSBMcQiVW from "/opt/buildhome/repo/plugins/formidable-form-builder.ts";
import google_analytics_client_KruZkwwzId from "/opt/buildhome/repo/plugins/google-analytics.client.ts";
import lightbox_client_2MDiyVSXfT from "/opt/buildhome/repo/plugins/lightbox.client.ts";
import modal_client_5sTXjXRW3L from "/opt/buildhome/repo/plugins/modal.client.ts";
import sentry_client_config_o5jjUx2pcO from "/opt/buildhome/repo/plugins/sentry.client.config.ts";
import theme_zupea9J243 from "/opt/buildhome/repo/plugins/theme.ts";
import toast_client_9z8IIRO0Dx from "/opt/buildhome/repo/plugins/toast.client.ts";
import vee_validate_client_P40JVl0wNI from "/opt/buildhome/repo/plugins/vee-validate.client.ts";
import velocity_client_4v06MdIA9C from "/opt/buildhome/repo/plugins/velocity.client.ts";
import vimeo_client_BFAm9LGErL from "/opt/buildhome/repo/plugins/vimeo.client.ts";
import watermark_client_Pmt0XmQZQ8 from "/opt/buildhome/repo/plugins/watermark.client.ts";
import backend_version_watcher_client_iqGYd6okqm from "/opt/buildhome/repo/plugins/backend-version-watcher.client.ts";
import graphql_client_9Pa4A2KAi9 from "/opt/buildhome/repo/plugins/graphql-client.ts";
export default [
  revive_payload_client_MgmDv1xcjH,
  unhead_wrD5m4ceUU,
  router_VjjVEq5nO5,
  _0_siteConfig_6jsMaX59ew,
  vuex_F8JCgj45hr,
  payload_client_OahDcTbMhh,
  navigation_repaint_client_M9tUDuA6En,
  check_outdated_build_client_GqvP5nF7PZ,
  chunk_reload_client_095CCeYrDs,
  plugin_vue3_Ivz3jFeXOQ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_3T5zV1B31D,
  plugin_51x8EwGcHS,
  _01_axios_trfC49DPAq,
  _01_cookies_rVe02PKG6J,
  _01_httpClient_h8ZAF6W6qM,
  _02_rest_client_fJWj9SMMdq,
  _02_userflow_client_D1CXvi5rkg,
  _03_hubspot_identity_client_ZVXR2lrEtt,
  _03_membershipApi_JTkBJU5CoA,
  _04_hubspot_live_chat_client_MYdZrEyANh,
  _05_bmAuth_VjB6OFhOHE,
  allPrices_Yj9VFMWy6I,
  assets_client_MHsgPPxPuR,
  browser_client_kZsmP6IGPw,
  capacity_database_Ak8crLfnjm,
  chartjs_1wC9lAM0Tp,
  cloudinary_SEKWrTzwvn,
  dateRangePicker_client_Hj8UQvsmQy,
  download_manager_gThQnK2QhW,
  file_pond_client_bWiN9gXVf8,
  formidable_form_builder_hwSBMcQiVW,
  google_analytics_client_KruZkwwzId,
  lightbox_client_2MDiyVSXfT,
  modal_client_5sTXjXRW3L,
  sentry_client_config_o5jjUx2pcO,
  theme_zupea9J243,
  toast_client_9z8IIRO0Dx,
  vee_validate_client_P40JVl0wNI,
  velocity_client_4v06MdIA9C,
  vimeo_client_BFAm9LGErL,
  watermark_client_Pmt0XmQZQ8,
  backend_version_watcher_client_iqGYd6okqm,
  graphql_client_9Pa4A2KAi9
]