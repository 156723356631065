import { defineNuxtPlugin } from 'nuxt/app'
import FormData from 'form-data'
import { HttpClientProxy } from '~/utils/http'

export default defineNuxtPlugin({
  name: 'httpClient',
  setup(nuxtApp) {
    const { $cookies, $axios, $config } = nuxtApp

    const get = (uri, data = null, options = {}) => {
      return sendRequest(uri, 'get', data, options)
    }

    const remove = (uri, data = null, options = {}) => {
      return sendRequest(uri, 'delete', data, options)
    }

    const post = (uri, data = null, options = {}) => {
      return sendRequest(uri, 'post', data, options)
    }

    const put = (uri, data = null, options = {}) => {
      return sendRequest(uri, 'put', data, options)
    }

    const patch = (uri, data = null, options = {}) => {
      return sendRequest(uri, 'patch', data, options)
    }

    const sendRequest = async (
      uri,
      method,
      data = null,
      customOptions = {},
    ) => {
      const options = {
        method,
        ...customOptions,
      }

      if (['post', 'put', 'patch'].includes(method)) {
        options.data = data
      }

      if (!(data instanceof FormData)) {
        options.headers = options.headers || {}
        options.headers['Content-type'] = 'application/json'
      }

      const httpProxy = new HttpClientProxy($axios, $cookies, $config, {
        maxNumberOfRetries: 1,
      })
      const { response, error } = await httpProxy.request(uri, options)

      if (error?.response?.status >= 400 || error?.code === 'ERR_CANCELED') {
        throw error.response?.data || error
      }

      return response?.data
    }

    return {
      provide: {
        httpClient: {
          get,
          post,
          put,
          patch,
          delete: remove,
        },
      },
    }
  },
})
