import { JWT_ACCESS_TOKEN, USER_INFO } from '~/utils/constants'
import useAuthStore from '~/stores/auth'

export default defineNuxtRouteMiddleware(async () => {
  const { $cookies } = useNuxtApp()
  const jwtToken = $cookies.get(JWT_ACCESS_TOKEN)
  const user = $cookies.get(USER_INFO)
  const authStore = useAuthStore()

  if (jwtToken && user) {
    authStore.setJwtToken(jwtToken)
    authStore.setUser(user)
  }
})
