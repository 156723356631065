export const VARIANT_CLASSES = {
  BASE: {
    primary: `primary-btn`,
    secondary: `secondary-btn`,
    tertiary: `tertiary-btn`,
    'tertiary-dark': `tertiary-dark-btn`,
    link: 'link-btn',
    clean: `clean-btn`,
  },

  DISABLED: {
    WITH_SPINNER: {
      primary: '',
      secondary: '',
    },
    WITHOUT_SPINNER: {
      primary: 'primary-btn--disabled',
      secondary: 'secondary-btn--disabled',
      tertiary: 'tertiary-btn--disabled',
      'tertiary-dark': 'tertiary-dark-btn--disabled',
    },
    HOVER: {
      primary: 'primary-btn--hover',
      secondary: 'secondary-btn--hover',
    },
    HOVER_WITHOUT_SPINNER: {
      primary:
        'hover:!bg-s-400 hover:!border-s-400 hover:!text-s-600 hover:!cursor-not-allowed',
      secondary:
        'hover:!text-s-600 hover:!bg-s-400 hover:!border-s-400 hover:!cursor-not-allowed',
    },
  },

  SPINNER: {
    primary: 'text-s-900',
    secondary: 'text-p-500',
  },

  DOWNLOAD: {
    primary: 'bg-p-900',
    secondary: 'bg-s-500',
    tertiary: 'bg-s-500',
    'tertiary-dark': 'bg-p-800',
  },
}
