export const blackListEmails = [
  '@126.com',
  '@139.com',
  '@163.com',
  '@AOL.COM',
  '@btinternet.com',
  '@comcast.net',
  '@fastmail.com',
  '@foxmail.com',
  '@gmai.com',
  '@gmail.com',
  '@googlemail.com',
  '@hanmail.net',
  '@icloud.com',
  '@iinet.net.au',
  '@internode.on.net',
  '@libero.it',
  '@mac.com',
  '@me.com',
  '@msn.com',
  '@naver.com',
  '@online.de',
  '@optonline.net',
  '@optusnet.com.au',
  '@orange.fr',
  '@ozemail.com.au',
  '@pm.me',
  '@protonmail.com',
  '@qq.com',
  '@rogers.com',
  '@shaw.ca',
  '@skynet.be',
  '@sympatico.ca',
  '@telus.net',
  '@tiscali.it',
  '@verizon.net',
  '@videotron.ca',
  '@vip.163.com',
  '@web.de',
  '@westnet.com.au',
  '@xtra.co.nz',
  '@y7mail.com',
  '@yahoofinance.com',
  '@ymail.com',
  '@bigpond.',
  '@mail.',
  '@gmx.',
  '@live.',
  '@outlook.',
  '@hotmail.',
  '@yahoo.',
]
