export const priceTab = {
  data: [
    {
      category: {
        id: null,
        name: null,
        alias: null,
        chemicalCode: null,
        isSustainable: false,
      },
      series: [
        {
          date: '2023-06-28',
          valueHigh: null,
          valueLow: null,
          valueMid: null,
        },
        {
          date: '2023-07-12',
          valueHigh: null,
          valueLow: null,
          valueMid: null,
        },
        {
          date: '2023-07-26',
          valueHigh: null,
          valueLow: null,
          valueMid: null,
        },
        {
          date: '2023-08-09',
          valueHigh: null,
          valueLow: null,
          valueMid: null,
        },
        {
          date: '2023-08-23',
          valueHigh: null,
          valueLow: null,
          valueMid: null,
        },
        {
          date: '2023-09-06',
          valueHigh: null,
          valueLow: null,
          valueMid: null,
        },
        {
          date: '2023-09-20',
          valueHigh: null,
          valueLow: null,
          valueMid: null,
        },
        {
          date: '2023-10-04',
          valueHigh: null,
          valueLow: null,
          valueMid: null,
        },
        {
          date: '2023-10-18',
          valueHigh: null,
          valueLow: null,
          valueMid: null,
        },
        {
          date: '2023-11-01',
          valueHigh: null,
          valueLow: null,
          valueMid: null,
        },
        {
          date: '2023-11-15',
          valueHigh: null,
          valueLow: null,
          valueMid: null,
        },
        {
          date: '2023-11-29',
          valueHigh: null,
          valueLow: null,
          valueMid: null,
        },
        {
          date: '2023-12-13',
          valueHigh: null,
          valueLow: null,
          valueMid: null,
        },
        {
          date: '2023-12-27',
          valueHigh: null,
          valueLow: null,
          valueMid: null,
        },
        {
          date: '2024-01-10',
          valueHigh: null,
          valueLow: null,
          valueMid: null,
        },
        {
          date: '2024-01-24',
          valueHigh: null,
          valueLow: null,
          valueMid: null,
        },
        {
          date: '2024-02-07',
          valueHigh: null,
          valueLow: null,
          valueMid: null,
        },
        {
          date: '2024-02-21',
          valueHigh: null,
          valueLow: null,
          valueMid: null,
        },
        {
          date: '2024-03-06',
          valueHigh: null,
          valueLow: null,
          valueMid: null,
        },
        {
          date: '2024-03-20',
          valueHigh: null,
          valueLow: null,
          valueMid: null,
        },
        {
          date: '2024-04-03',
          valueHigh: null,
          valueLow: null,
          valueMid: null,
        },
        {
          date: '2024-04-17',
          valueHigh: null,
          valueLow: null,
          valueMid: null,
        },
        {
          date: '2024-05-01',
          valueHigh: null,
          valueLow: null,
          valueMid: null,
        },
        {
          date: '2024-05-15',
          valueHigh: null,
          valueLow: null,
          valueMid: null,
        },
      ],
    },
  ],
  unitOfMeasure: { name: 'tonne', symbol: 't' },
  currency: {
    name: 'United States Dollar',
    iso: 'USD',
    symbol: '$',
  },
  hideChartComponent: true,
}
