import { v4 as uuidv4 } from 'uuid'
import { defineNuxtPlugin } from 'nuxt/app'
import loginGql from '~/queries/login.gql'
import { getAdaptedUser } from '@/utils/adapters'
import {
  BACKEND_VERSION_COOKIE,
  DEFAULT_OPTIONS,
  JWT_ACCESS_TOKEN,
  MEMBERSHIPS,
  REFRESH_TOKEN,
  USER_INFO,
} from '~/utils/constants'
import { GraphQlClientProxy } from '~/utils/http'
import useAuthStore from '~/stores/auth'

/**
 * This is a client to handle all the authentication flow with graphql
 *
 * @method login
 * @method register
 */
export default defineNuxtPlugin({
  name: 'bmAuth',
  dependsOn: ['cookies', 'vuex', 'axios'],
  setup(nuxtApp) {
    const {
      $cookies,
      $store,
      $config,
      $axios,
      $hubspotIdentity,
      $hubspotLiveChat,
      $userflow,
      $graphql,
    }: any = nuxtApp
    const authStore = useAuthStore()
    const jwtToken = useCookie(JWT_ACCESS_TOKEN)
    const authenticatedClient = new GraphQlClientProxy({
      graphQLClient: $graphql.authenticatedClient,
      $cookies,
      $config,
      options: { maxNumberOfRetries: 1 },
    })

    const unAuthenticatedClient = new GraphQlClientProxy({
      graphQLClient: $graphql.default,
      $cookies,
      $config,
    })

    const defaultClient = $config.public.isStellateEnabled
      ? unAuthenticatedClient
      : authenticatedClient

    const logout = () => {
      authStore.setJwtToken(null)
      authStore.setUser(null)
      $store.commit('auth/setJwtToken', null)
      $store.commit('auth/setUser', null)
      $store.commit('memberships/clearKeys')

      $cookies.remove(JWT_ACCESS_TOKEN)
      $cookies.remove(REFRESH_TOKEN)
      $cookies.remove(USER_INFO)
      $cookies.remove(MEMBERSHIPS)
      $hubspotLiveChat.removeLiveChat()
    }

    const login = async (form) => {
      const response = await authenticatedClient.mutate({
        mutation: loginGql,
        variables: {
          ...form,
          clientMutationId: uuidv4(),
        },
      })

      const { authToken, refreshToken, user } = response.data.login
      const adaptedUser = getAdaptedUser(user)

      $cookies.set(JWT_ACCESS_TOKEN, authToken, DEFAULT_OPTIONS)
      $cookies.set(REFRESH_TOKEN, refreshToken, DEFAULT_OPTIONS)
      $cookies.set(USER_INFO, adaptedUser, DEFAULT_OPTIONS)
      $cookies.set(
        BACKEND_VERSION_COOKIE,
        (await getBackendVersion()).version,
        DEFAULT_OPTIONS,
      )

      $hubspotIdentity.identifyUser()
      $hubspotLiveChat.initLiveChat(adaptedUser.email)
      if ($userflow) {
        await $userflow.identify(adaptedUser.id, {
          device_type: window.innerWidth > 800 ? 'desktop' : 'mobile',
          authorised_user: true,
        })
      }

      authStore.setJwtToken(authToken)
      authStore.setUser(adaptedUser)
      $store.commit('auth/setJwtToken', authToken)
      $store.commit('auth/setUser', adaptedUser)

      return response.data
    }

    const isAuthenticated = () => {
      return !!jwtToken.value
    }

    /**
     * Get an graphql client based on the authentication status of the client
     *
     * For unauthenticated users, they will use the defaultClient. It uses a GET request for all queries
     * For authenticate users, they will use authenticatedClient. It uses a POST request, so won't care about the LightSpeed GET caching system
     *
     * @returns an graphql client
     */
    const getGraphqlClient = () => {
      return isAuthenticated() ? authenticatedClient : defaultClient
    }

    /**
     * Client to get information that doesn't require authentication. It will use GET request
     * to take advantage of the GET caching server system
     *
     * @returns a graphql client that uses GET request
     */
    const getPublicGraphqlClient = () => defaultClient

    const getAuthenticatedClient = () => authenticatedClient

    // This endpoint is added here because of it is required by this plugin, and the
    // order of the plugin imports matters, and restClient cannot be imported before
    // this plugin because of other plugin dependencies.
    const getBackendVersion = async () => {
      return (
        await $axios.get(`${$config.public.apiRestEndpoint}/backend-version`)
      ).data
    }

    return {
      provide: {
        bmAuth: {
          login,
          logout,
          isAuthenticated,
          getGraphqlClient,
          getPublicGraphqlClient,
          getAuthenticatedClient,
          getBackendVersion,
        },
      },
    }
  },
})
