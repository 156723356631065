import userflow from 'userflow.js'
import { defineNuxtPlugin } from 'nuxt/app'
import { USER_INFO } from '~/utils/constants'

export default defineNuxtPlugin(async (nuxtApp) => {
  const { $config, $cookies }: any = nuxtApp
  const user = $cookies.get(USER_INFO)

  const userflowApiKey = $config.public.userflowIdToken
  if (!userflowApiKey) return

  userflow.init(userflowApiKey)

  if (user) {
    await userflow.identify(user.id, {
      device_type: window.innerWidth > 800 ? 'desktop' : 'mobile',
      authorised_user: true,
    })
  } else {
    await userflow.identifyAnonymous({
      device_type: window.innerWidth > 800 ? 'desktop' : 'mobile',
      authorised_user: false,
    })
  }
  return { provide: { userflow } }
})
