const DYNAMIC_REDIRECTS = [
  {
    from: /^\/events\/world-tour-2020\/.*$/,
    to: '/events/world-tour-2020/',
  },
  {
    from: /^\/glossary\/.*$/,
    to: '/',
  },
  {
    from: /^\/worldtour2017\/.*$/,
    to: '/events/world-tour-2017/',
  },
  {
    from: /^\/events\/benchmark-week-2021\/.*$/,
    to: 'https://events.benchmarkminerals.com/d/rfqx5b',
  },
  {
    from: /^\/events\/benchmark-week-2022\/.*$/,
    to: 'https://events.benchmarkminerals.com/d/rfqx5b',
  },
]

export default DYNAMIC_REDIRECTS
