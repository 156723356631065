
          export const options = {
  "includeNodeModules": false,
  "options": {},
  "clients": {
    "default": {
      "endpoint": "https://benchmark-prod.stellate.sh",
      "options": {
        "requireCredentials": false
      }
    },
    "authenticatedClient": {
      "endpoint": "https://wp-admin.benchmarkminerals.com/wp/graphql",
      "options": {
        "requireCredentials": true
      }
    }
  }
}
        