import { defineNuxtPlugin } from 'nuxt/app'
import { USER_INFO } from '~/utils/constants'
import { logError } from '~/utils/log-error'
import {
  loadLiveChat,
  openLiveChat,
  refreshLiveChat,
  removeLiveChat,
} from '~/utils/hubspot'

export default defineNuxtPlugin({
  name: 'bmAuth',
  dependsOn: ['cookies', 'restClient'],
  async setup(nuxtApp) {
    const { $cookies, $store, $restClient }: any = nuxtApp
    const user = $cookies.get(USER_INFO)

    window.hsConversationsSettings = {
      loadImmediately: false,
    }

    const initLiveChat = (email: string) => {
      $store.dispatch('liveChat/setEnable', false)
      $restClient
        .getUserHubspot()
        .then(({ token }) => {
          loadLiveChat({ email, token })
          $store.dispatch('liveChat/setEnable', true)
        })
        .catch((e) => {
          $store.dispatch('liveChat/setEnable', false)
          logError(e)
        })
    }

    if (user) {
      initLiveChat(user.email)
    }

    return {
      provide: {
        hubspotLiveChat: {
          loadLiveChat,
          openLiveChat,
          removeLiveChat,
          initLiveChat,
          refreshLiveChat,
        },
      },
    }
  },
})
