import { default as aboutcGvMViY0SnMeta } from "/opt/buildhome/repo/pages/about.vue?macro=true";
import { default as api_45accessIiHUWoTbPWMeta } from "/opt/buildhome/repo/pages/account/api-access.vue?macro=true";
import { default as indexy4DdxYIhQyMeta } from "/opt/buildhome/repo/pages/account/index.vue?macro=true";
import { default as subscriptionstPsCZnawRQMeta } from "/opt/buildhome/repo/pages/account/subscriptions.vue?macro=true";
import { default as _91slug_93CDA7w8McfPMeta } from "/opt/buildhome/repo/pages/announcements/[slug].vue?macro=true";
import { default as indexlHCjcTvsLZMeta } from "/opt/buildhome/repo/pages/announcements/index.vue?macro=true";
import { default as _91slug_930WwzBuN9eRMeta } from "/opt/buildhome/repo/pages/anode/[slug].vue?macro=true";
import { default as _91slug_93YWhTatzR1eMeta } from "/opt/buildhome/repo/pages/anode/anode-prices/[slug].vue?macro=true";
import { default as indexALekGYzXBoMeta } from "/opt/buildhome/repo/pages/anode/capacity-database/index.vue?macro=true";
import { default as _91slug_93jr7g2PCa9KMeta } from "/opt/buildhome/repo/pages/anode/data-reports/[slug].vue?macro=true";
import { default as _91id_93GKxnNFMpD9Meta } from "/opt/buildhome/repo/pages/anode/data-reports/forecast/video/[id].vue?macro=true";
import { default as _91id_93gLOvt0xbXDMeta } from "/opt/buildhome/repo/pages/anode/data-reports/price-assessments/report/[id].vue?macro=true";
import { default as _91id_933gfbL7YqVgMeta } from "/opt/buildhome/repo/pages/anode/data-reports/price-assessments/video/[id].vue?macro=true";
import { default as index7NnI0o2OQnMeta } from "/opt/buildhome/repo/pages/anode/index.vue?macro=true";
import { default as _91slug_93iL6G6VY7SLMeta } from "/opt/buildhome/repo/pages/anode/market-insights/[slug].vue?macro=true";
import { default as docs1nOMXHzh0TMeta } from "/opt/buildhome/repo/pages/api/docs.vue?macro=true";
import { default as index0XDxs8O6pDMeta } from "/opt/buildhome/repo/pages/api/index.vue?macro=true";
import { default as appCIzZhzdW1jMeta } from "/opt/buildhome/repo/pages/app.vue?macro=true";
import { default as battery_45emissions_45analyserkyh8ACuK4UMeta } from "/opt/buildhome/repo/pages/battery-emissions-analyser.vue?macro=true";
import { default as _91slug_93fqj5tQyb8nMeta } from "/opt/buildhome/repo/pages/blog-archive/[slug].vue?macro=true";
import { default as indexj1RthwGIxaMeta } from "/opt/buildhome/repo/pages/blog-archive/index.vue?macro=true";
import { default as _91slug_93TPOL4DnYbVMeta } from "/opt/buildhome/repo/pages/cathode/[slug].vue?macro=true";
import { default as _91slug_93jxvFEO26RkMeta } from "/opt/buildhome/repo/pages/cathode/capacity-database/[slug].vue?macro=true";
import { default as _91slug_93eFOasq8s8uMeta } from "/opt/buildhome/repo/pages/cathode/cathode-prices/[slug].vue?macro=true";
import { default as _91slug_93vJaJpQCJTmMeta } from "/opt/buildhome/repo/pages/cathode/data-reports/[slug].vue?macro=true";
import { default as _91id_937hLsWAU91QMeta } from "/opt/buildhome/repo/pages/cathode/data-reports/forecast/video/[id].vue?macro=true";
import { default as _91id_93NpDZD4dTVfMeta } from "/opt/buildhome/repo/pages/cathode/data-reports/market-assessments/report/[id].vue?macro=true";
import { default as _91id_933kGiyPhT5GMeta } from "/opt/buildhome/repo/pages/cathode/data-reports/market-assessments/video/[id].vue?macro=true";
import { default as _91id_93R1oaOpGoKoMeta } from "/opt/buildhome/repo/pages/cathode/data-reports/price-assessments/report/[id].vue?macro=true";
import { default as _91id_93JphEkIB9fCMeta } from "/opt/buildhome/repo/pages/cathode/data-reports/price-assessments/video/[id].vue?macro=true";
import { default as indexqsLC1dgovCMeta } from "/opt/buildhome/repo/pages/cathode/index.vue?macro=true";
import { default as _91slug_93xAuMzgXJpiMeta } from "/opt/buildhome/repo/pages/cathode/market-insights/[slug].vue?macro=true";
import { default as _91slug_93XSeE0LmJSOMeta } from "/opt/buildhome/repo/pages/cobalt/[slug].vue?macro=true";
import { default as _91slug_937kAP5yLvLpMeta } from "/opt/buildhome/repo/pages/cobalt/cobalt-prices/[slug].vue?macro=true";
import { default as _91slug_93WpRiqobNstMeta } from "/opt/buildhome/repo/pages/cobalt/data-reports/[slug].vue?macro=true";
import { default as _91id_93NVtWXDabsaMeta } from "/opt/buildhome/repo/pages/cobalt/data-reports/forecast/report/[id].vue?macro=true";
import { default as _91id_93K31ZCmb1NwMeta } from "/opt/buildhome/repo/pages/cobalt/data-reports/forecast/video/[id].vue?macro=true";
import { default as _91id_93jSJzg1enZwMeta } from "/opt/buildhome/repo/pages/cobalt/data-reports/price-assessments/report/[id].vue?macro=true";
import { default as _91id_93AkELhq3cHOMeta } from "/opt/buildhome/repo/pages/cobalt/data-reports/price-assessments/video/[id].vue?macro=true";
import { default as _91id_93ReWak3o4zoMeta } from "/opt/buildhome/repo/pages/cobalt/data-reports/sustainability/video/[id].vue?macro=true";
import { default as indexy2ztjZxwwkMeta } from "/opt/buildhome/repo/pages/cobalt/index.vue?macro=true";
import { default as _91slug_93xyCLul9x8vMeta } from "/opt/buildhome/repo/pages/cobalt/market-insights/[slug].vue?macro=true";
import { default as _91slug_93fgnt359sNyMeta } from "/opt/buildhome/repo/pages/cobalt/sustainability/[slug].vue?macro=true";
import { default as consultancypsHirthA55Meta } from "/opt/buildhome/repo/pages/consultancy.vue?macro=true";
import { default as contactupLWW5VsuQMeta } from "/opt/buildhome/repo/pages/contact.vue?macro=true";
import { default as cookiesZLbBnefQgdMeta } from "/opt/buildhome/repo/pages/cookies.vue?macro=true";
import { default as copper_45weeklyeU2QSPlzKSMeta } from "/opt/buildhome/repo/pages/copper-weekly.vue?macro=true";
import { default as _91slug_93R0cMrO6ka1Meta } from "/opt/buildhome/repo/pages/downloadform/[slug].vue?macro=true";
import { default as esg1fSkcHPvdwMeta } from "/opt/buildhome/repo/pages/esg.vue?macro=true";
import { default as _91slug_93GVUh6co81jMeta } from "/opt/buildhome/repo/pages/events/[slug].vue?macro=true";
import { default as index8VtZ9dOfhKMeta } from "/opt/buildhome/repo/pages/events/africa-day/index.vue?macro=true";
import { default as index3hfMaiHwFbMeta } from "/opt/buildhome/repo/pages/events/battery-day-2020/index.vue?macro=true";
import { default as indexAhgi3MVWPiMeta } from "/opt/buildhome/repo/pages/events/battery-gigafactories-usa-2022/index.vue?macro=true";
import { default as indexKnpHPiboO0Meta } from "/opt/buildhome/repo/pages/events/battery-megafactories-europe-2022/index.vue?macro=true";
import { default as locationGURw8TzK7tMeta } from "/opt/buildhome/repo/pages/events/battery-megafactories-europe-2022/location.vue?macro=true";
import { default as indexKiVxJV5PbMMeta } from "/opt/buildhome/repo/pages/events/benchmark-lithium-summit-2018/index.vue?macro=true";
import { default as indexdG2i3qSKNDMeta } from "/opt/buildhome/repo/pages/events/benchmark-minerals-summit-2020/index.vue?macro=true";
import { default as indexOirNK1FOQZMeta } from "/opt/buildhome/repo/pages/events/benchmark-week-online-2021/index.vue?macro=true";
import { default as agenda9hHorjJAVJMeta } from "/opt/buildhome/repo/pages/events/cathodes-2018/agenda.vue?macro=true";
import { default as indexemuFkBpud5Meta } from "/opt/buildhome/repo/pages/events/cathodes-2018/index.vue?macro=true";
import { default as locationPnFVEYxkXwMeta } from "/opt/buildhome/repo/pages/events/cathodes-2018/location.vue?macro=true";
import { default as site_45visitsrKHdFPSvgAMeta } from "/opt/buildhome/repo/pages/events/cathodes-2018/site-visits.vue?macro=true";
import { default as sponsorsC94iQqFtdXMeta } from "/opt/buildhome/repo/pages/events/cathodes-2018/sponsors.vue?macro=true";
import { default as agendaUHWmqLGe1KMeta } from "/opt/buildhome/repo/pages/events/cathodes-2019/agenda.vue?macro=true";
import { default as field_45trip1Yrt9bL2tCMeta } from "/opt/buildhome/repo/pages/events/cathodes-2019/field-trip.vue?macro=true";
import { default as indexv5BEasyX4OMeta } from "/opt/buildhome/repo/pages/events/cathodes-2019/index.vue?macro=true";
import { default as locationkufxPKdRwHMeta } from "/opt/buildhome/repo/pages/events/cathodes-2019/location.vue?macro=true";
import { default as sponsors3ZUdUZCKC9Meta } from "/opt/buildhome/repo/pages/events/cathodes-2019/sponsors.vue?macro=true";
import { default as agendaSmOuU4VId7Meta } from "/opt/buildhome/repo/pages/events/cathodes2017/agenda.vue?macro=true";
import { default as indexlXeVAnami2Meta } from "/opt/buildhome/repo/pages/events/cathodes2017/index.vue?macro=true";
import { default as locationPcSdnpU1EcMeta } from "/opt/buildhome/repo/pages/events/cathodes2017/location.vue?macro=true";
import { default as sponsorsQdsSBnieouMeta } from "/opt/buildhome/repo/pages/events/cathodes2017/sponsors.vue?macro=true";
import { default as indexrluBWiKeYKMeta } from "/opt/buildhome/repo/pages/events/ev-festival/index.vue?macro=true";
import { default as indexxLX1JxP2oEMeta } from "/opt/buildhome/repo/pages/events/evfest2021/index.vue?macro=true";
import { default as agendan7ai3oBrWWMeta } from "/opt/buildhome/repo/pages/events/graphite-and-anodes-2018/agenda.vue?macro=true";
import { default as indexeBZslTxfyWMeta } from "/opt/buildhome/repo/pages/events/graphite-and-anodes-2018/index.vue?macro=true";
import { default as locationUBXWu5z3w4Meta } from "/opt/buildhome/repo/pages/events/graphite-and-anodes-2018/location.vue?macro=true";
import { default as sponsorsGxZISQnLLAMeta } from "/opt/buildhome/repo/pages/events/graphite-and-anodes-2018/sponsors.vue?macro=true";
import { default as agendauATx9gtMIMMeta } from "/opt/buildhome/repo/pages/events/graphite-and-anodes-2019/agenda.vue?macro=true";
import { default as indexkqUXHfgsTkMeta } from "/opt/buildhome/repo/pages/events/graphite-and-anodes-2019/index.vue?macro=true";
import { default as locationCELUIOsEKyMeta } from "/opt/buildhome/repo/pages/events/graphite-and-anodes-2019/location.vue?macro=true";
import { default as sponsorsH3728K5gwBMeta } from "/opt/buildhome/repo/pages/events/graphite-and-anodes-2019/sponsors.vue?macro=true";
import { default as agendauRTHEEGMJ2Meta } from "/opt/buildhome/repo/pages/events/graphite2016/agenda.vue?macro=true";
import { default as hosts45gc7D6O4zMeta } from "/opt/buildhome/repo/pages/events/graphite2016/hosts.vue?macro=true";
import { default as indexa5HAK56fWkMeta } from "/opt/buildhome/repo/pages/events/graphite2016/index.vue?macro=true";
import { default as locationWVrSPTQaOcMeta } from "/opt/buildhome/repo/pages/events/graphite2016/location.vue?macro=true";
import { default as sponsors3YM88acNxaMeta } from "/opt/buildhome/repo/pages/events/graphite2016/sponsors.vue?macro=true";
import { default as agendasizKnnzGgoMeta } from "/opt/buildhome/repo/pages/events/graphite2017/agenda.vue?macro=true";
import { default as hosts7zOJS72fHKMeta } from "/opt/buildhome/repo/pages/events/graphite2017/hosts.vue?macro=true";
import { default as indexAg3l3jPKLrMeta } from "/opt/buildhome/repo/pages/events/graphite2017/index.vue?macro=true";
import { default as locationhghJmO0K2uMeta } from "/opt/buildhome/repo/pages/events/graphite2017/location.vue?macro=true";
import { default as sponsorsKFob4dGsq5Meta } from "/opt/buildhome/repo/pages/events/graphite2017/sponsors.vue?macro=true";
import { default as indexjDV0ZxvnYzMeta } from "/opt/buildhome/repo/pages/events/index.vue?macro=true";
import { default as indexO3p5kJCN3XMeta } from "/opt/buildhome/repo/pages/events/nickel-day/index.vue?macro=true";
import { default as pastdZ7VYmohzxMeta } from "/opt/buildhome/repo/pages/events/past.vue?macro=true";
import { default as webinars4SL1J08xY7Meta } from "/opt/buildhome/repo/pages/events/webinars.vue?macro=true";
import { default as chicagoM74ZlfAJbUMeta } from "/opt/buildhome/repo/pages/events/world-tour-2018/chicago.vue?macro=true";
import { default as germanySJq8Q36mFzMeta } from "/opt/buildhome/repo/pages/events/world-tour-2018/germany.vue?macro=true";
import { default as hong_45kongTQk75pnWK0Meta } from "/opt/buildhome/repo/pages/events/world-tour-2018/hong-kong.vue?macro=true";
import { default as indexPT89hYr8YKMeta } from "/opt/buildhome/repo/pages/events/world-tour-2018/index.vue?macro=true";
import { default as london_45day_452cWbLEiZ5nvMeta } from "/opt/buildhome/repo/pages/events/world-tour-2018/london-day-2.vue?macro=true";
import { default as london7ChDLfTfWxMeta } from "/opt/buildhome/repo/pages/events/world-tour-2018/london.vue?macro=true";
import { default as melbourneT7E4JIazV3Meta } from "/opt/buildhome/repo/pages/events/world-tour-2018/melbourne.vue?macro=true";
import { default as new_45yorkprtxowcLJjMeta } from "/opt/buildhome/repo/pages/events/world-tour-2018/new-york.vue?macro=true";
import { default as perthGc7CE39tSfMeta } from "/opt/buildhome/repo/pages/events/world-tour-2018/perth.vue?macro=true";
import { default as san_45franciscocH2NNiZH0XMeta } from "/opt/buildhome/repo/pages/events/world-tour-2018/san-francisco.vue?macro=true";
import { default as seoulFnqRpbqH6tMeta } from "/opt/buildhome/repo/pages/events/world-tour-2018/seoul.vue?macro=true";
import { default as sydney06rRugV6OeMeta } from "/opt/buildhome/repo/pages/events/world-tour-2018/sydney.vue?macro=true";
import { default as tokyocP7lXBa7lNMeta } from "/opt/buildhome/repo/pages/events/world-tour-2018/tokyo.vue?macro=true";
import { default as torontogKaZRft0c7Meta } from "/opt/buildhome/repo/pages/events/world-tour-2018/toronto.vue?macro=true";
import { default as vancouverMZyyt0esXWMeta } from "/opt/buildhome/repo/pages/events/world-tour-2018/vancouver.vue?macro=true";
import { default as adelaideR2rMMhpq6jMeta } from "/opt/buildhome/repo/pages/events/world-tour-2019/adelaide.vue?macro=true";
import { default as cape_45townb52IZ4pl4RMeta } from "/opt/buildhome/repo/pages/events/world-tour-2019/cape-town.vue?macro=true";
import { default as chicagoUAPaZN6QuaMeta } from "/opt/buildhome/repo/pages/events/world-tour-2019/chicago.vue?macro=true";
import { default as germany9f0rSNhBfqMeta } from "/opt/buildhome/repo/pages/events/world-tour-2019/germany.vue?macro=true";
import { default as hong_45kongDUAJAHEzLeMeta } from "/opt/buildhome/repo/pages/events/world-tour-2019/hong-kong.vue?macro=true";
import { default as index09VnTIbVHsMeta } from "/opt/buildhome/repo/pages/events/world-tour-2019/index.vue?macro=true";
import { default as londonXenQWS9R8DMeta } from "/opt/buildhome/repo/pages/events/world-tour-2019/london.vue?macro=true";
import { default as melbourneE3lG8itTHLMeta } from "/opt/buildhome/repo/pages/events/world-tour-2019/melbourne.vue?macro=true";
import { default as new_45york_4523HKX016YpsMeta } from "/opt/buildhome/repo/pages/events/world-tour-2019/new-york-2.vue?macro=true";
import { default as new_45yorkHT8QEvFbJsMeta } from "/opt/buildhome/repo/pages/events/world-tour-2019/new-york.vue?macro=true";
import { default as perthkXd6b7Wv3cMeta } from "/opt/buildhome/repo/pages/events/world-tour-2019/perth.vue?macro=true";
import { default as san_45franciscosbecrOqESIMeta } from "/opt/buildhome/repo/pages/events/world-tour-2019/san-francisco.vue?macro=true";
import { default as seoul9gF3KYp5MWMeta } from "/opt/buildhome/repo/pages/events/world-tour-2019/seoul.vue?macro=true";
import { default as sydneyagDin0cwbsMeta } from "/opt/buildhome/repo/pages/events/world-tour-2019/sydney.vue?macro=true";
import { default as tokyoyC9gASokS7Meta } from "/opt/buildhome/repo/pages/events/world-tour-2019/tokyo.vue?macro=true";
import { default as torontoF7XV1iAyErMeta } from "/opt/buildhome/repo/pages/events/world-tour-2019/toronto.vue?macro=true";
import { default as vancouverFyzqDVsgDqMeta } from "/opt/buildhome/repo/pages/events/world-tour-2019/vancouver.vue?macro=true";
import { default as indexx7RvgwAwfIMeta } from "/opt/buildhome/repo/pages/events/world-tour-2020/index.vue?macro=true";
import { default as _91slug_93wQbO1ETjfAMeta } from "/opt/buildhome/repo/pages/events/world-tour-2022/[slug].vue?macro=true";
import { default as indexiWbL6E8oiBMeta } from "/opt/buildhome/repo/pages/events/world-tour-2022/index.vue?macro=true";
import { default as indexPe8xFb2JELMeta } from "/opt/buildhome/repo/pages/events/world-tour-2023-new-york/index.vue?macro=true";
import { default as index4L4AO0PDmiMeta } from "/opt/buildhome/repo/pages/events/world-tour-online/index.vue?macro=true";
import { default as world_45tourx96qJG9EWgMeta } from "/opt/buildhome/repo/pages/events/world-tour.vue?macro=true";
import { default as canadaCcmwYuB62qMeta } from "/opt/buildhome/repo/pages/events/worldtour-2015/canada.vue?macro=true";
import { default as hongkonglFj1vvhgpEMeta } from "/opt/buildhome/repo/pages/events/worldtour-2015/hongkong.vue?macro=true";
import { default as indexo8VbWY1V0OMeta } from "/opt/buildhome/repo/pages/events/worldtour-2015/index.vue?macro=true";
import { default as londonu3Aw2UjXMGMeta } from "/opt/buildhome/repo/pages/events/worldtour-2015/london.vue?macro=true";
import { default as melbourneWaPuiZK7c4Meta } from "/opt/buildhome/repo/pages/events/worldtour-2015/melbourne.vue?macro=true";
import { default as newyork8r4uyKMMS1Meta } from "/opt/buildhome/repo/pages/events/worldtour-2015/newyork.vue?macro=true";
import { default as sydneyv9Qb0JeTdlMeta } from "/opt/buildhome/repo/pages/events/worldtour-2015/sydney.vue?macro=true";
import { default as tokyoBm8NvgwWBwMeta } from "/opt/buildhome/repo/pages/events/worldtour-2015/tokyo.vue?macro=true";
import { default as torontoFS298skmkbMeta } from "/opt/buildhome/repo/pages/events/worldtour-2015/toronto.vue?macro=true";
import { default as canadaTxrt0tqblwMeta } from "/opt/buildhome/repo/pages/events/worldtour-2016/canada.vue?macro=true";
import { default as germanyOimOUkCISZMeta } from "/opt/buildhome/repo/pages/events/worldtour-2016/germany.vue?macro=true";
import { default as hongkongvnm9Y2ptckMeta } from "/opt/buildhome/repo/pages/events/worldtour-2016/hongkong.vue?macro=true";
import { default as indexkXdgSeDR9lMeta } from "/opt/buildhome/repo/pages/events/worldtour-2016/index.vue?macro=true";
import { default as londonwh26AgVSEdMeta } from "/opt/buildhome/repo/pages/events/worldtour-2016/london.vue?macro=true";
import { default as melbournePgUonFnhy7Meta } from "/opt/buildhome/repo/pages/events/worldtour-2016/melbourne.vue?macro=true";
import { default as newyorkMr6E85Q05nMeta } from "/opt/buildhome/repo/pages/events/worldtour-2016/newyork.vue?macro=true";
import { default as siliconFZCwd5sLGNMeta } from "/opt/buildhome/repo/pages/events/worldtour-2016/silicon.vue?macro=true";
import { default as sydneyuzz21FymNSMeta } from "/opt/buildhome/repo/pages/events/worldtour-2016/sydney.vue?macro=true";
import { default as tokyoJRb6KV8gUgMeta } from "/opt/buildhome/repo/pages/events/worldtour-2016/tokyo.vue?macro=true";
import { default as torontoBJlFkEnUisMeta } from "/opt/buildhome/repo/pages/events/worldtour-2016/toronto.vue?macro=true";
import { default as washingtonSL0wkLIerqMeta } from "/opt/buildhome/repo/pages/events/worldtour-2016/washington.vue?macro=true";
import { default as beijingiIlZHopsJwMeta } from "/opt/buildhome/repo/pages/events/worldtour2017/beijing.vue?macro=true";
import { default as germanyRs6N2ExCq6Meta } from "/opt/buildhome/repo/pages/events/worldtour2017/germany.vue?macro=true";
import { default as hongkongqYAkAGC1WfMeta } from "/opt/buildhome/repo/pages/events/worldtour2017/hongkong.vue?macro=true";
import { default as indexsVNte7bYqVMeta } from "/opt/buildhome/repo/pages/events/worldtour2017/index.vue?macro=true";
import { default as londonaNiUll4LdtMeta } from "/opt/buildhome/repo/pages/events/worldtour2017/london.vue?macro=true";
import { default as melbourneJonPF6wa7KMeta } from "/opt/buildhome/repo/pages/events/worldtour2017/melbourne.vue?macro=true";
import { default as newyork2gBTu9KJiLMeta } from "/opt/buildhome/repo/pages/events/worldtour2017/newyork.vue?macro=true";
import { default as perthTM9bm9w7W2Meta } from "/opt/buildhome/repo/pages/events/worldtour2017/perth.vue?macro=true";
import { default as seoulJRaI0OFK2EMeta } from "/opt/buildhome/repo/pages/events/worldtour2017/seoul.vue?macro=true";
import { default as sydneyoI9RWMJQRSMeta } from "/opt/buildhome/repo/pages/events/worldtour2017/sydney.vue?macro=true";
import { default as tokyoNCpQz3UbowMeta } from "/opt/buildhome/repo/pages/events/worldtour2017/tokyo.vue?macro=true";
import { default as toronto0UFr3iwlevMeta } from "/opt/buildhome/repo/pages/events/worldtour2017/toronto.vue?macro=true";
import { default as vancouvernBMd3Hw92nMeta } from "/opt/buildhome/repo/pages/events/worldtour2017/vancouver.vue?macro=true";
import { default as washingtondcTWkXLphanMMeta } from "/opt/buildhome/repo/pages/events/worldtour2017/washingtondc.vue?macro=true";
import { default as zurichRmDRgb3h0WMeta } from "/opt/buildhome/repo/pages/events/worldtour2017/zurich.vue?macro=true";
import { default as excel_45add_45inESjHEAIXpnMeta } from "/opt/buildhome/repo/pages/excel-add-in.vue?macro=true";
import { default as _91slug_93m9d5pmxgytMeta } from "/opt/buildhome/repo/pages/fluorspar/[slug].vue?macro=true";
import { default as _91slug_93HF760RRQB3Meta } from "/opt/buildhome/repo/pages/fluorspar/data-reports/[slug].vue?macro=true";
import { default as _91id_93hExFuJD1F5Meta } from "/opt/buildhome/repo/pages/fluorspar/data-reports/forecast/video/[id].vue?macro=true";
import { default as indexI6YuS0AXdnMeta } from "/opt/buildhome/repo/pages/fluorspar/index.vue?macro=true";
import { default as _91slug_932n9wtfrMoEMeta } from "/opt/buildhome/repo/pages/fluorspar/market-insights/[slug].vue?macro=true";
import { default as forecastsWqQWc0MH9zMeta } from "/opt/buildhome/repo/pages/forecasts.vue?macro=true";
import { default as stellantisCOCjzzEt6eMeta } from "/opt/buildhome/repo/pages/forms/stellantis.vue?macro=true";
import { default as independent_45practitioners_45assurance_45reportQ4CTpIwSo1Meta } from "/opt/buildhome/repo/pages/independent-practitioners-assurance-report.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as industry_45reportsXRM6SkO9dRMeta } from "/opt/buildhome/repo/pages/industry-reports.vue?macro=true";
import { default as join_45usKPp119MpARMeta } from "/opt/buildhome/repo/pages/join-us.vue?macro=true";
import { default as lcaXXKTXCZSNiMeta } from "/opt/buildhome/repo/pages/lca.vue?macro=true";
import { default as _91slug_93MJpvOrzMcMMeta } from "/opt/buildhome/repo/pages/lithium-ion-batteries/[slug].vue?macro=true";
import { default as _91slug_93Dk36JDjKYPMeta } from "/opt/buildhome/repo/pages/lithium-ion-batteries/capacity-database/[slug].vue?macro=true";
import { default as _91slug_93fCXI8Kkj6SMeta } from "/opt/buildhome/repo/pages/lithium-ion-batteries/data-reports/[slug].vue?macro=true";
import { default as _91id_93qjjGSQHsrsMeta } from "/opt/buildhome/repo/pages/lithium-ion-batteries/data-reports/forecast/video/[id].vue?macro=true";
import { default as _91id_93Q4bryZEVxwMeta } from "/opt/buildhome/repo/pages/lithium-ion-batteries/data-reports/market-assessments/report/[id].vue?macro=true";
import { default as _91id_93BNsENjRKExMeta } from "/opt/buildhome/repo/pages/lithium-ion-batteries/data-reports/market-assessments/video/[id].vue?macro=true";
import { default as _91id_93uvW4matU0wMeta } from "/opt/buildhome/repo/pages/lithium-ion-batteries/data-reports/price-assessments/report/[id].vue?macro=true";
import { default as _91id_93bnTFu7Cso5Meta } from "/opt/buildhome/repo/pages/lithium-ion-batteries/data-reports/price-assessments/video/[id].vue?macro=true";
import { default as indexGxu7xHcGAPMeta } from "/opt/buildhome/repo/pages/lithium-ion-batteries/index.vue?macro=true";
import { default as _91slug_93fYooskxAB4Meta } from "/opt/buildhome/repo/pages/lithium-ion-batteries/lithium-ion-batteries-prices/[slug].vue?macro=true";
import { default as _91slug_93TZAZGL4LLnMeta } from "/opt/buildhome/repo/pages/lithium-ion-batteries/market-insights/[slug].vue?macro=true";
import { default as lithium_45ion_45battery_45pricesWg5OajvvIzMeta } from "/opt/buildhome/repo/pages/lithium-ion-battery-prices.vue?macro=true";
import { default as lithium_45ion_45battery_45raw_45material_45indexRIvIeFr6rQMeta } from "/opt/buildhome/repo/pages/lithium-ion-battery-raw-material-index.vue?macro=true";
import { default as _91slug_93TZC7LZQ1hXMeta } from "/opt/buildhome/repo/pages/lithium-price-assessment-portal/[slug].vue?macro=true";
import { default as lithium_45prices0bROrAVXWlMeta } from "/opt/buildhome/repo/pages/lithium-prices.vue?macro=true";
import { default as _91slug_93vPapDA9bxGMeta } from "/opt/buildhome/repo/pages/lithium/[slug].vue?macro=true";
import { default as _91slug_93UxpUB4jQWMMeta } from "/opt/buildhome/repo/pages/lithium/data-reports/[slug].vue?macro=true";
import { default as _91id_93G5YOdD8ZTaMeta } from "/opt/buildhome/repo/pages/lithium/data-reports/forecast/report/[id].vue?macro=true";
import { default as _91id_93fmAFBXScABMeta } from "/opt/buildhome/repo/pages/lithium/data-reports/forecast/video/[id].vue?macro=true";
import { default as _91id_93j3VKrmy2FlMeta } from "/opt/buildhome/repo/pages/lithium/data-reports/price-assessments/report/[id].vue?macro=true";
import { default as _91id_93aIGcnZXokhMeta } from "/opt/buildhome/repo/pages/lithium/data-reports/price-assessments/video/[id].vue?macro=true";
import { default as _91id_93JmSdogUXoQMeta } from "/opt/buildhome/repo/pages/lithium/data-reports/sustainability/video/[id].vue?macro=true";
import { default as index9Oz1g1h8rZMeta } from "/opt/buildhome/repo/pages/lithium/index.vue?macro=true";
import { default as _91slug_93Y9YgWyRJY3Meta } from "/opt/buildhome/repo/pages/lithium/lithium-prices/[slug].vue?macro=true";
import { default as _91slug_93zbjItBZHVtMeta } from "/opt/buildhome/repo/pages/lithium/market-insights/[slug].vue?macro=true";
import { default as _91slug_939qKP4XJPGoMeta } from "/opt/buildhome/repo/pages/lithium/sustainability/[slug].vue?macro=true";
import { default as login4dfr6kRqBoMeta } from "/opt/buildhome/repo/pages/login.vue?macro=true";
import { default as _91slug_93ARB9fUizniMeta } from "/opt/buildhome/repo/pages/manganese/[slug].vue?macro=true";
import { default as _91slug_93uJGvzAt1K8Meta } from "/opt/buildhome/repo/pages/manganese/data-reports/[slug].vue?macro=true";
import { default as _91id_93ayLMAJ9HXTMeta } from "/opt/buildhome/repo/pages/manganese/data-reports/forecast/video/[id].vue?macro=true";
import { default as index54AsFA2d51Meta } from "/opt/buildhome/repo/pages/manganese/index.vue?macro=true";
import { default as _91slug_93d1XmpkuTQFMeta } from "/opt/buildhome/repo/pages/manganese/market-insights/[slug].vue?macro=true";
import { default as market_45assessmentsLZygAaQAt2Meta } from "/opt/buildhome/repo/pages/market-assessments.vue?macro=true";
import { default as _91slug_93K5vELg6AahMeta } from "/opt/buildhome/repo/pages/membership/[slug].vue?macro=true";
import { default as indexbgbFWcyi4kMeta } from "/opt/buildhome/repo/pages/methodologies/index.vue?macro=true";
import { default as gigafactoriesNXWIlegKvVMeta } from "/opt/buildhome/repo/pages/methodologies/market-assessments/gigafactories.vue?macro=true";
import { default as mineral_45pricing_45chartOm1MadzOs4Meta } from "/opt/buildhome/repo/pages/mineral-pricing-chart.vue?macro=true";
import { default as _91slug_93grrjy4huDcMeta } from "/opt/buildhome/repo/pages/natural-graphite/[slug].vue?macro=true";
import { default as _91slug_93aMjdEaukH2Meta } from "/opt/buildhome/repo/pages/natural-graphite/data-reports/[slug].vue?macro=true";
import { default as _91id_93pQVBZfImDSMeta } from "/opt/buildhome/repo/pages/natural-graphite/data-reports/forecast/video/[id].vue?macro=true";
import { default as _91id_93wj7B8NrLkLMeta } from "/opt/buildhome/repo/pages/natural-graphite/data-reports/price-assessments/report/[id].vue?macro=true";
import { default as _91id_937xnswVrX96Meta } from "/opt/buildhome/repo/pages/natural-graphite/data-reports/price-assessments/video/[id].vue?macro=true";
import { default as _91id_93KDNVF1vzItMeta } from "/opt/buildhome/repo/pages/natural-graphite/data-reports/sustainability/video/[id].vue?macro=true";
import { default as indexeD23UhSKGEMeta } from "/opt/buildhome/repo/pages/natural-graphite/index.vue?macro=true";
import { default as _91slug_93s2zwPnnLwDMeta } from "/opt/buildhome/repo/pages/natural-graphite/market-insights/[slug].vue?macro=true";
import { default as _91slug_936v79G5C5CoMeta } from "/opt/buildhome/repo/pages/natural-graphite/natural-graphite-prices/[slug].vue?macro=true";
import { default as _91slug_934ei19UR1UbMeta } from "/opt/buildhome/repo/pages/natural-graphite/sustainability/[slug].vue?macro=true";
import { default as newsletter_45signupdnjg7fnChUMeta } from "/opt/buildhome/repo/pages/newsletter-signup.vue?macro=true";
import { default as _91slug_93JpK6pyKRngMeta } from "/opt/buildhome/repo/pages/nickel/[slug].vue?macro=true";
import { default as _91slug_93gKV4YsSk2zMeta } from "/opt/buildhome/repo/pages/nickel/data-reports/[slug].vue?macro=true";
import { default as _91id_93axc201z98hMeta } from "/opt/buildhome/repo/pages/nickel/data-reports/forecast/report/[id].vue?macro=true";
import { default as _91id_93uZPEQ88GnbMeta } from "/opt/buildhome/repo/pages/nickel/data-reports/forecast/video/[id].vue?macro=true";
import { default as _91id_93uPsAmLKwnJMeta } from "/opt/buildhome/repo/pages/nickel/data-reports/price-assessments/report/[id].vue?macro=true";
import { default as _91id_93b6LqianLRmMeta } from "/opt/buildhome/repo/pages/nickel/data-reports/price-assessments/video/[id].vue?macro=true";
import { default as _91id_93sRJxzYi9efMeta } from "/opt/buildhome/repo/pages/nickel/data-reports/sustainability/video/[id].vue?macro=true";
import { default as indexkC9jVE9k93Meta } from "/opt/buildhome/repo/pages/nickel/index.vue?macro=true";
import { default as _91slug_93nTrGiTk7hRMeta } from "/opt/buildhome/repo/pages/nickel/market-insights/[slug].vue?macro=true";
import { default as _91slug_93JCadwomZa0Meta } from "/opt/buildhome/repo/pages/nickel/nickel-prices/[slug].vue?macro=true";
import { default as _91slug_935WRuBVYGmuMeta } from "/opt/buildhome/repo/pages/nickel/sustainability/[slug].vue?macro=true";
import { default as _91slug_93Ac3qa6HURUMeta } from "/opt/buildhome/repo/pages/phosphate/[slug].vue?macro=true";
import { default as _91slug_935168CrC6CpMeta } from "/opt/buildhome/repo/pages/phosphate/data-reports/[slug].vue?macro=true";
import { default as _91id_93VhVoVCO2R5Meta } from "/opt/buildhome/repo/pages/phosphate/data-reports/forecast/video/[id].vue?macro=true";
import { default as _91id_93CV6zuFH4a1Meta } from "/opt/buildhome/repo/pages/phosphate/data-reports/report/[id].vue?macro=true";
import { default as indexKVnlTIdCXzMeta } from "/opt/buildhome/repo/pages/phosphate/index.vue?macro=true";
import { default as _91slug_93dICUrCiwsIMeta } from "/opt/buildhome/repo/pages/phosphate/market-insights/[slug].vue?macro=true";
import { default as policy_45newsletter4ngWgXL5sxMeta } from "/opt/buildhome/repo/pages/policy-newsletter.vue?macro=true";
import { default as _91id_93h4oibBAr7rMeta } from "/opt/buildhome/repo/pages/preview/_postType/[id].vue?macro=true";
import { default as _91id_93dA2eCpime3Meta } from "/opt/buildhome/repo/pages/price-assessments/[slug]/[id].vue?macro=true";
import { default as _91id_935gwX0FavvDMeta } from "/opt/buildhome/repo/pages/price-assessments/[slug]/reports/[id].vue?macro=true";
import { default as indexVnchHRinAMMeta } from "/opt/buildhome/repo/pages/price-assessments/index.vue?macro=true";
import { default as chart_45builderuwfe2Hcji0Meta } from "/opt/buildhome/repo/pages/price-dashboard/chart-builder.vue?macro=true";
import { default as index8vsS0KUWJUMeta } from "/opt/buildhome/repo/pages/price-dashboard/index.vue?macro=true";
import { default as _91id_93CTA9r8Rz0oMeta } from "/opt/buildhome/repo/pages/price-dashboard/watchlist/[id].vue?macro=true";
import { default as indexxwCInpLYwrMeta } from "/opt/buildhome/repo/pages/price-dashboard/watchlist/index.vue?macro=true";
import { default as price_45spotlight_45signupb55AzOHoQBMeta } from "/opt/buildhome/repo/pages/price-spotlight-signup.vue?macro=true";
import { default as privacy69l1Gm1VSzMeta } from "/opt/buildhome/repo/pages/privacy.vue?macro=true";
import { default as _91slug_93EgPgNvkJrBMeta } from "/opt/buildhome/repo/pages/rare-earths/[slug].vue?macro=true";
import { default as _91slug_93N10fcNNa4nMeta } from "/opt/buildhome/repo/pages/rare-earths/data-reports/[slug].vue?macro=true";
import { default as _91id_93VRn94lp38eMeta } from "/opt/buildhome/repo/pages/rare-earths/data-reports/forecast/video/[id].vue?macro=true";
import { default as _91id_93BemgRhGmXKMeta } from "/opt/buildhome/repo/pages/rare-earths/data-reports/price-assessments/report/[id].vue?macro=true";
import { default as _91id_93V8ZgNKFBNFMeta } from "/opt/buildhome/repo/pages/rare-earths/data-reports/price-assessments/video/[id].vue?macro=true";
import { default as _91id_93j3OxlYYCVEMeta } from "/opt/buildhome/repo/pages/rare-earths/data-reports/sustainability/video/[id].vue?macro=true";
import { default as indexgEXpI3bsM6Meta } from "/opt/buildhome/repo/pages/rare-earths/index.vue?macro=true";
import { default as _91slug_93yue1L2QGc9Meta } from "/opt/buildhome/repo/pages/rare-earths/market-insights/[slug].vue?macro=true";
import { default as _91slug_93pABT8zOdoaMeta } from "/opt/buildhome/repo/pages/rare-earths/rare-earths-prices/[slug].vue?macro=true";
import { default as _91slug_93RvWQAPMjvsMeta } from "/opt/buildhome/repo/pages/rare-earths/sustainability/[slug].vue?macro=true";
import { default as _91slug_93YUXJlKvyz4Meta } from "/opt/buildhome/repo/pages/recycling/[slug].vue?macro=true";
import { default as _91slug_93b4ndwMla3wMeta } from "/opt/buildhome/repo/pages/recycling/data-reports/[slug].vue?macro=true";
import { default as _91id_93DUqCU7ZKyoMeta } from "/opt/buildhome/repo/pages/recycling/data-reports/forecast/video/[id].vue?macro=true";
import { default as _91id_93JT6bUi57fUMeta } from "/opt/buildhome/repo/pages/recycling/data-reports/price-assessments/report/[id].vue?macro=true";
import { default as _91id_93YYDMcQTRTQMeta } from "/opt/buildhome/repo/pages/recycling/data-reports/price-assessments/video/[id].vue?macro=true";
import { default as indexYD3lNtJcwpMeta } from "/opt/buildhome/repo/pages/recycling/index.vue?macro=true";
import { default as _91slug_93zNgyC2zEOJMeta } from "/opt/buildhome/repo/pages/recycling/market-insights/[slug].vue?macro=true";
import { default as _91slug_93CnE3qVIMhLMeta } from "/opt/buildhome/repo/pages/recycling/recycling-prices/[slug].vue?macro=true";
import { default as request_45for_45proposalYLW0rW47dtMeta } from "/opt/buildhome/repo/pages/request-for-proposal.vue?macro=true";
import { default as reset_45passwordR3tuU8TAkoMeta } from "/opt/buildhome/repo/pages/reset-password.vue?macro=true";
import { default as set_45password7SadfZikENMeta } from "/opt/buildhome/repo/pages/set-password.vue?macro=true";
import { default as _91slug_93ujTsshKqfjMeta } from "/opt/buildhome/repo/pages/silicon-anode/[slug].vue?macro=true";
import { default as _91slug_93SPpHRj5eZtMeta } from "/opt/buildhome/repo/pages/silicon-anode/data-reports/[slug].vue?macro=true";
import { default as _91id_936zmPdUHCtaMeta } from "/opt/buildhome/repo/pages/silicon-anode/data-reports/forecast/video/[id].vue?macro=true";
import { default as _91id_93CBxd6MSfb5Meta } from "/opt/buildhome/repo/pages/silicon-anode/data-reports/report/[id].vue?macro=true";
import { default as _91id_933JqQ5sb1SwMeta } from "/opt/buildhome/repo/pages/silicon-anode/data-reports/sustainability/video/[id].vue?macro=true";
import { default as indexSsQIUyPOObMeta } from "/opt/buildhome/repo/pages/silicon-anode/index.vue?macro=true";
import { default as _91slug_93ZqQ2aiNdCRMeta } from "/opt/buildhome/repo/pages/silicon-anode/market-insights/[slug].vue?macro=true";
import { default as _91slug_933z2s4zCBqAMeta } from "/opt/buildhome/repo/pages/sodium-ion-batteries/capacity-database/[slug].vue?macro=true";
import { default as indexqdcseVYEWFMeta } from "/opt/buildhome/repo/pages/sodium-ion-batteries/capacity-database/index.vue?macro=true";
import { default as _91slug_93eU3AZtsRr2Meta } from "/opt/buildhome/repo/pages/sodium-ion-batteries/data-reports/[slug].vue?macro=true";
import { default as _91id_93zQrnEojYkYMeta } from "/opt/buildhome/repo/pages/sodium-ion-batteries/data-reports/forecast/video/[id].vue?macro=true";
import { default as _91id_93qena2G7tBCMeta } from "/opt/buildhome/repo/pages/sodium-ion-batteries/data-reports/market-assessments/report/[id].vue?macro=true";
import { default as _91id_93zokBOr0uPMMeta } from "/opt/buildhome/repo/pages/sodium-ion-batteries/data-reports/market-assessments/video/[id].vue?macro=true";
import { default as _91id_93tUbavR9xo0Meta } from "/opt/buildhome/repo/pages/sodium-ion-batteries/data-reports/report/[id].vue?macro=true";
import { default as indexnvEi1iGIDVMeta } from "/opt/buildhome/repo/pages/sodium-ion-batteries/index.vue?macro=true";
import { default as _91slug_935u3uP5AuNzMeta } from "/opt/buildhome/repo/pages/sodium-ion-batteries/market-insights/[slug].vue?macro=true";
import { default as _91slug_93wkGfuwQyc0Meta } from "/opt/buildhome/repo/pages/solid-state/[slug].vue?macro=true";
import { default as _91slug_93xgPuqImAfNMeta } from "/opt/buildhome/repo/pages/solid-state/data-reports/[slug].vue?macro=true";
import { default as _91id_938Y4l3qA3J9Meta } from "/opt/buildhome/repo/pages/solid-state/data-reports/forecast/video/[id].vue?macro=true";
import { default as _91id_937WdK7kVlCvMeta } from "/opt/buildhome/repo/pages/solid-state/data-reports/report/[id].vue?macro=true";
import { default as indexmQDcWUWQBFMeta } from "/opt/buildhome/repo/pages/solid-state/index.vue?macro=true";
import { default as _91slug_93Q9iJZNNVhiMeta } from "/opt/buildhome/repo/pages/solid-state/market-insights/[slug].vue?macro=true";
import { default as _91slug_93FGFJwhQoDNMeta } from "/opt/buildhome/repo/pages/synthetic-graphite/[slug].vue?macro=true";
import { default as _91slug_93dMpE1LOwDvMeta } from "/opt/buildhome/repo/pages/synthetic-graphite/data-reports/[slug].vue?macro=true";
import { default as _91id_93u7Gcc8DozUMeta } from "/opt/buildhome/repo/pages/synthetic-graphite/data-reports/forecast/video/[id].vue?macro=true";
import { default as _91id_93QfD7Tc6LVOMeta } from "/opt/buildhome/repo/pages/synthetic-graphite/data-reports/price-assessments/report/[id].vue?macro=true";
import { default as _91id_93NnlvLXp7RWMeta } from "/opt/buildhome/repo/pages/synthetic-graphite/data-reports/price-assessments/video/[id].vue?macro=true";
import { default as _91id_93Qk8zM6y5dDMeta } from "/opt/buildhome/repo/pages/synthetic-graphite/data-reports/sustainability/video/[id].vue?macro=true";
import { default as indexNhdCvu5I6TMeta } from "/opt/buildhome/repo/pages/synthetic-graphite/index.vue?macro=true";
import { default as _91slug_93FZ8cKa6hAMMeta } from "/opt/buildhome/repo/pages/synthetic-graphite/market-insights/[slug].vue?macro=true";
import { default as _91slug_93mrWTYY1mL5Meta } from "/opt/buildhome/repo/pages/synthetic-graphite/sustainability/[slug].vue?macro=true";
import { default as _91slug_93oZyP7IPyVAMeta } from "/opt/buildhome/repo/pages/synthetic-graphite/synthetic-graphite-prices/[slug].vue?macro=true";
import { default as termsjw1825O2ZdMeta } from "/opt/buildhome/repo/pages/terms.vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    component: () => import("/opt/buildhome/repo/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "account-api-access",
    path: "/account/api-access",
    meta: api_45accessIiHUWoTbPWMeta || {},
    component: () => import("/opt/buildhome/repo/pages/account/api-access.vue").then(m => m.default || m)
  },
  {
    name: "account",
    path: "/account",
    meta: indexy4DdxYIhQyMeta || {},
    component: () => import("/opt/buildhome/repo/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account-subscriptions",
    path: "/account/subscriptions",
    meta: subscriptionstPsCZnawRQMeta || {},
    component: () => import("/opt/buildhome/repo/pages/account/subscriptions.vue").then(m => m.default || m)
  },
  {
    name: "announcements-slug",
    path: "/announcements/:slug()",
    meta: _91slug_93CDA7w8McfPMeta || {},
    component: () => import("/opt/buildhome/repo/pages/announcements/[slug].vue").then(m => m.default || m)
  },
  {
    name: "announcements",
    path: "/announcements",
    component: () => import("/opt/buildhome/repo/pages/announcements/index.vue").then(m => m.default || m)
  },
  {
    name: "anode-slug",
    path: "/anode/:slug()",
    component: () => import("/opt/buildhome/repo/pages/anode/[slug].vue").then(m => m.default || m)
  },
  {
    name: "anode-anode-prices-slug",
    path: "/anode/anode-prices/:slug()",
    meta: _91slug_93YWhTatzR1eMeta || {},
    component: () => import("/opt/buildhome/repo/pages/anode/anode-prices/[slug].vue").then(m => m.default || m)
  },
  {
    name: "anode-capacity-database",
    path: "/anode/capacity-database",
    meta: indexALekGYzXBoMeta || {},
    component: () => import("/opt/buildhome/repo/pages/anode/capacity-database/index.vue").then(m => m.default || m)
  },
  {
    name: "anode-data-reports-slug",
    path: "/anode/data-reports/:slug()",
    meta: _91slug_93jr7g2PCa9KMeta || {},
    component: () => import("/opt/buildhome/repo/pages/anode/data-reports/[slug].vue").then(m => m.default || m)
  },
  {
    name: "anode-data-reports-forecast-video-id",
    path: "/anode/data-reports/forecast/video/:id()",
    component: () => import("/opt/buildhome/repo/pages/anode/data-reports/forecast/video/[id].vue").then(m => m.default || m)
  },
  {
    name: "anode-data-reports-price-assessments-report-id",
    path: "/anode/data-reports/price-assessments/report/:id()",
    meta: _91id_93gLOvt0xbXDMeta || {},
    component: () => import("/opt/buildhome/repo/pages/anode/data-reports/price-assessments/report/[id].vue").then(m => m.default || m)
  },
  {
    name: "anode-data-reports-price-assessments-video-id",
    path: "/anode/data-reports/price-assessments/video/:id()",
    meta: _91id_933gfbL7YqVgMeta || {},
    component: () => import("/opt/buildhome/repo/pages/anode/data-reports/price-assessments/video/[id].vue").then(m => m.default || m)
  },
  {
    name: "anode",
    path: "/anode",
    meta: index7NnI0o2OQnMeta || {},
    component: () => import("/opt/buildhome/repo/pages/anode/index.vue").then(m => m.default || m)
  },
  {
    name: "anode-market-insights-slug",
    path: "/anode/market-insights/:slug()",
    meta: _91slug_93iL6G6VY7SLMeta || {},
    component: () => import("/opt/buildhome/repo/pages/anode/market-insights/[slug].vue").then(m => m.default || m)
  },
  {
    name: "api-docs",
    path: "/api/docs",
    meta: docs1nOMXHzh0TMeta || {},
    component: () => import("/opt/buildhome/repo/pages/api/docs.vue").then(m => m.default || m)
  },
  {
    name: "api",
    path: "/api",
    component: () => import("/opt/buildhome/repo/pages/api/index.vue").then(m => m.default || m)
  },
  {
    name: "app",
    path: "/app",
    component: () => import("/opt/buildhome/repo/pages/app.vue").then(m => m.default || m)
  },
  {
    name: "battery-emissions-analyser",
    path: "/battery-emissions-analyser",
    meta: battery_45emissions_45analyserkyh8ACuK4UMeta || {},
    component: () => import("/opt/buildhome/repo/pages/battery-emissions-analyser.vue").then(m => m.default || m)
  },
  {
    name: "blog-archive-slug",
    path: "/blog-archive/:slug()",
    meta: _91slug_93fqj5tQyb8nMeta || {},
    component: () => import("/opt/buildhome/repo/pages/blog-archive/[slug].vue").then(m => m.default || m)
  },
  {
    name: "blog-archive",
    path: "/blog-archive",
    component: () => import("/opt/buildhome/repo/pages/blog-archive/index.vue").then(m => m.default || m)
  },
  {
    name: "cathode-slug",
    path: "/cathode/:slug()",
    component: () => import("/opt/buildhome/repo/pages/cathode/[slug].vue").then(m => m.default || m)
  },
  {
    name: "cathode-capacity-database-slug",
    path: "/cathode/capacity-database/:slug()",
    meta: _91slug_93jxvFEO26RkMeta || {},
    component: () => import("/opt/buildhome/repo/pages/cathode/capacity-database/[slug].vue").then(m => m.default || m)
  },
  {
    name: "cathode-cathode-prices-slug",
    path: "/cathode/cathode-prices/:slug()",
    meta: _91slug_93eFOasq8s8uMeta || {},
    component: () => import("/opt/buildhome/repo/pages/cathode/cathode-prices/[slug].vue").then(m => m.default || m)
  },
  {
    name: "cathode-data-reports-slug",
    path: "/cathode/data-reports/:slug()",
    meta: _91slug_93vJaJpQCJTmMeta || {},
    component: () => import("/opt/buildhome/repo/pages/cathode/data-reports/[slug].vue").then(m => m.default || m)
  },
  {
    name: "cathode-data-reports-forecast-video-id",
    path: "/cathode/data-reports/forecast/video/:id()",
    component: () => import("/opt/buildhome/repo/pages/cathode/data-reports/forecast/video/[id].vue").then(m => m.default || m)
  },
  {
    name: "cathode-data-reports-market-assessments-report-id",
    path: "/cathode/data-reports/market-assessments/report/:id()",
    component: () => import("/opt/buildhome/repo/pages/cathode/data-reports/market-assessments/report/[id].vue").then(m => m.default || m)
  },
  {
    name: "cathode-data-reports-market-assessments-video-id",
    path: "/cathode/data-reports/market-assessments/video/:id()",
    component: () => import("/opt/buildhome/repo/pages/cathode/data-reports/market-assessments/video/[id].vue").then(m => m.default || m)
  },
  {
    name: "cathode-data-reports-price-assessments-report-id",
    path: "/cathode/data-reports/price-assessments/report/:id()",
    meta: _91id_93R1oaOpGoKoMeta || {},
    component: () => import("/opt/buildhome/repo/pages/cathode/data-reports/price-assessments/report/[id].vue").then(m => m.default || m)
  },
  {
    name: "cathode-data-reports-price-assessments-video-id",
    path: "/cathode/data-reports/price-assessments/video/:id()",
    meta: _91id_93JphEkIB9fCMeta || {},
    component: () => import("/opt/buildhome/repo/pages/cathode/data-reports/price-assessments/video/[id].vue").then(m => m.default || m)
  },
  {
    name: "cathode",
    path: "/cathode",
    meta: indexqsLC1dgovCMeta || {},
    component: () => import("/opt/buildhome/repo/pages/cathode/index.vue").then(m => m.default || m)
  },
  {
    name: "cathode-market-insights-slug",
    path: "/cathode/market-insights/:slug()",
    meta: _91slug_93xAuMzgXJpiMeta || {},
    component: () => import("/opt/buildhome/repo/pages/cathode/market-insights/[slug].vue").then(m => m.default || m)
  },
  {
    name: "cobalt-slug",
    path: "/cobalt/:slug()",
    component: () => import("/opt/buildhome/repo/pages/cobalt/[slug].vue").then(m => m.default || m)
  },
  {
    name: "cobalt-cobalt-prices-slug",
    path: "/cobalt/cobalt-prices/:slug()",
    meta: _91slug_937kAP5yLvLpMeta || {},
    component: () => import("/opt/buildhome/repo/pages/cobalt/cobalt-prices/[slug].vue").then(m => m.default || m)
  },
  {
    name: "cobalt-data-reports-slug",
    path: "/cobalt/data-reports/:slug()",
    meta: _91slug_93WpRiqobNstMeta || {},
    component: () => import("/opt/buildhome/repo/pages/cobalt/data-reports/[slug].vue").then(m => m.default || m)
  },
  {
    name: "cobalt-data-reports-forecast-report-id",
    path: "/cobalt/data-reports/forecast/report/:id()",
    component: () => import("/opt/buildhome/repo/pages/cobalt/data-reports/forecast/report/[id].vue").then(m => m.default || m)
  },
  {
    name: "cobalt-data-reports-forecast-video-id",
    path: "/cobalt/data-reports/forecast/video/:id()",
    component: () => import("/opt/buildhome/repo/pages/cobalt/data-reports/forecast/video/[id].vue").then(m => m.default || m)
  },
  {
    name: "cobalt-data-reports-price-assessments-report-id",
    path: "/cobalt/data-reports/price-assessments/report/:id()",
    meta: _91id_93jSJzg1enZwMeta || {},
    component: () => import("/opt/buildhome/repo/pages/cobalt/data-reports/price-assessments/report/[id].vue").then(m => m.default || m)
  },
  {
    name: "cobalt-data-reports-price-assessments-video-id",
    path: "/cobalt/data-reports/price-assessments/video/:id()",
    component: () => import("/opt/buildhome/repo/pages/cobalt/data-reports/price-assessments/video/[id].vue").then(m => m.default || m)
  },
  {
    name: "cobalt-data-reports-sustainability-video-id",
    path: "/cobalt/data-reports/sustainability/video/:id()",
    meta: _91id_93ReWak3o4zoMeta || {},
    component: () => import("/opt/buildhome/repo/pages/cobalt/data-reports/sustainability/video/[id].vue").then(m => m.default || m)
  },
  {
    name: "cobalt",
    path: "/cobalt",
    meta: indexy2ztjZxwwkMeta || {},
    component: () => import("/opt/buildhome/repo/pages/cobalt/index.vue").then(m => m.default || m)
  },
  {
    name: "cobalt-market-insights-slug",
    path: "/cobalt/market-insights/:slug()",
    meta: _91slug_93xyCLul9x8vMeta || {},
    component: () => import("/opt/buildhome/repo/pages/cobalt/market-insights/[slug].vue").then(m => m.default || m)
  },
  {
    name: "cobalt-sustainability-slug",
    path: "/cobalt/sustainability/:slug()",
    meta: _91slug_93fgnt359sNyMeta || {},
    component: () => import("/opt/buildhome/repo/pages/cobalt/sustainability/[slug].vue").then(m => m.default || m)
  },
  {
    name: "consultancy",
    path: "/consultancy",
    component: () => import("/opt/buildhome/repo/pages/consultancy.vue").then(m => m.default || m)
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/opt/buildhome/repo/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "cookies",
    path: "/cookies",
    component: () => import("/opt/buildhome/repo/pages/cookies.vue").then(m => m.default || m)
  },
  {
    name: "copper-weekly",
    path: "/copper-weekly",
    component: () => import("/opt/buildhome/repo/pages/copper-weekly.vue").then(m => m.default || m)
  },
  {
    name: "downloadform-slug",
    path: "/downloadform/:slug()",
    component: () => import("/opt/buildhome/repo/pages/downloadform/[slug].vue").then(m => m.default || m)
  },
  {
    name: "esg",
    path: "/esg",
    meta: esg1fSkcHPvdwMeta || {},
    component: () => import("/opt/buildhome/repo/pages/esg.vue").then(m => m.default || m)
  },
  {
    name: "events-slug",
    path: "/events/:slug()",
    meta: _91slug_93GVUh6co81jMeta || {},
    component: () => import("/opt/buildhome/repo/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: "events-africa-day",
    path: "/events/africa-day",
    component: () => import("/opt/buildhome/repo/pages/events/africa-day/index.vue").then(m => m.default || m)
  },
  {
    name: "events-battery-day-2020",
    path: "/events/battery-day-2020",
    component: () => import("/opt/buildhome/repo/pages/events/battery-day-2020/index.vue").then(m => m.default || m)
  },
  {
    name: "events-battery-gigafactories-usa-2022",
    path: "/events/battery-gigafactories-usa-2022",
    component: () => import("/opt/buildhome/repo/pages/events/battery-gigafactories-usa-2022/index.vue").then(m => m.default || m)
  },
  {
    name: "events-battery-megafactories-europe-2022",
    path: "/events/battery-megafactories-europe-2022",
    component: () => import("/opt/buildhome/repo/pages/events/battery-megafactories-europe-2022/index.vue").then(m => m.default || m)
  },
  {
    name: "events-battery-megafactories-europe-2022-location",
    path: "/events/battery-megafactories-europe-2022/location",
    component: () => import("/opt/buildhome/repo/pages/events/battery-megafactories-europe-2022/location.vue").then(m => m.default || m)
  },
  {
    name: "events-benchmark-lithium-summit-2018",
    path: "/events/benchmark-lithium-summit-2018",
    component: () => import("/opt/buildhome/repo/pages/events/benchmark-lithium-summit-2018/index.vue").then(m => m.default || m)
  },
  {
    name: "events-benchmark-minerals-summit-2020",
    path: "/events/benchmark-minerals-summit-2020",
    component: () => import("/opt/buildhome/repo/pages/events/benchmark-minerals-summit-2020/index.vue").then(m => m.default || m)
  },
  {
    name: "events-benchmark-week-online-2021",
    path: "/events/benchmark-week-online-2021",
    component: () => import("/opt/buildhome/repo/pages/events/benchmark-week-online-2021/index.vue").then(m => m.default || m)
  },
  {
    name: "events-cathodes-2018-agenda",
    path: "/events/cathodes-2018/agenda",
    component: () => import("/opt/buildhome/repo/pages/events/cathodes-2018/agenda.vue").then(m => m.default || m)
  },
  {
    name: "events-cathodes-2018",
    path: "/events/cathodes-2018",
    component: () => import("/opt/buildhome/repo/pages/events/cathodes-2018/index.vue").then(m => m.default || m)
  },
  {
    name: "events-cathodes-2018-location",
    path: "/events/cathodes-2018/location",
    component: () => import("/opt/buildhome/repo/pages/events/cathodes-2018/location.vue").then(m => m.default || m)
  },
  {
    name: "events-cathodes-2018-site-visits",
    path: "/events/cathodes-2018/site-visits",
    component: () => import("/opt/buildhome/repo/pages/events/cathodes-2018/site-visits.vue").then(m => m.default || m)
  },
  {
    name: "events-cathodes-2018-sponsors",
    path: "/events/cathodes-2018/sponsors",
    component: () => import("/opt/buildhome/repo/pages/events/cathodes-2018/sponsors.vue").then(m => m.default || m)
  },
  {
    name: "events-cathodes-2019-agenda",
    path: "/events/cathodes-2019/agenda",
    component: () => import("/opt/buildhome/repo/pages/events/cathodes-2019/agenda.vue").then(m => m.default || m)
  },
  {
    name: "events-cathodes-2019-field-trip",
    path: "/events/cathodes-2019/field-trip",
    component: () => import("/opt/buildhome/repo/pages/events/cathodes-2019/field-trip.vue").then(m => m.default || m)
  },
  {
    name: "events-cathodes-2019",
    path: "/events/cathodes-2019",
    component: () => import("/opt/buildhome/repo/pages/events/cathodes-2019/index.vue").then(m => m.default || m)
  },
  {
    name: "events-cathodes-2019-location",
    path: "/events/cathodes-2019/location",
    component: () => import("/opt/buildhome/repo/pages/events/cathodes-2019/location.vue").then(m => m.default || m)
  },
  {
    name: "events-cathodes-2019-sponsors",
    path: "/events/cathodes-2019/sponsors",
    component: () => import("/opt/buildhome/repo/pages/events/cathodes-2019/sponsors.vue").then(m => m.default || m)
  },
  {
    name: "events-cathodes2017-agenda",
    path: "/events/cathodes2017/agenda",
    component: () => import("/opt/buildhome/repo/pages/events/cathodes2017/agenda.vue").then(m => m.default || m)
  },
  {
    name: "events-cathodes2017",
    path: "/events/cathodes2017",
    component: () => import("/opt/buildhome/repo/pages/events/cathodes2017/index.vue").then(m => m.default || m)
  },
  {
    name: "events-cathodes2017-location",
    path: "/events/cathodes2017/location",
    component: () => import("/opt/buildhome/repo/pages/events/cathodes2017/location.vue").then(m => m.default || m)
  },
  {
    name: "events-cathodes2017-sponsors",
    path: "/events/cathodes2017/sponsors",
    component: () => import("/opt/buildhome/repo/pages/events/cathodes2017/sponsors.vue").then(m => m.default || m)
  },
  {
    name: "events-ev-festival",
    path: "/events/ev-festival",
    component: () => import("/opt/buildhome/repo/pages/events/ev-festival/index.vue").then(m => m.default || m)
  },
  {
    name: "events-evfest2021",
    path: "/events/evfest2021",
    component: () => import("/opt/buildhome/repo/pages/events/evfest2021/index.vue").then(m => m.default || m)
  },
  {
    name: "events-graphite-and-anodes-2018-agenda",
    path: "/events/graphite-and-anodes-2018/agenda",
    component: () => import("/opt/buildhome/repo/pages/events/graphite-and-anodes-2018/agenda.vue").then(m => m.default || m)
  },
  {
    name: "events-graphite-and-anodes-2018",
    path: "/events/graphite-and-anodes-2018",
    component: () => import("/opt/buildhome/repo/pages/events/graphite-and-anodes-2018/index.vue").then(m => m.default || m)
  },
  {
    name: "events-graphite-and-anodes-2018-location",
    path: "/events/graphite-and-anodes-2018/location",
    component: () => import("/opt/buildhome/repo/pages/events/graphite-and-anodes-2018/location.vue").then(m => m.default || m)
  },
  {
    name: "events-graphite-and-anodes-2018-sponsors",
    path: "/events/graphite-and-anodes-2018/sponsors",
    component: () => import("/opt/buildhome/repo/pages/events/graphite-and-anodes-2018/sponsors.vue").then(m => m.default || m)
  },
  {
    name: "events-graphite-and-anodes-2019-agenda",
    path: "/events/graphite-and-anodes-2019/agenda",
    component: () => import("/opt/buildhome/repo/pages/events/graphite-and-anodes-2019/agenda.vue").then(m => m.default || m)
  },
  {
    name: "events-graphite-and-anodes-2019",
    path: "/events/graphite-and-anodes-2019",
    component: () => import("/opt/buildhome/repo/pages/events/graphite-and-anodes-2019/index.vue").then(m => m.default || m)
  },
  {
    name: "events-graphite-and-anodes-2019-location",
    path: "/events/graphite-and-anodes-2019/location",
    component: () => import("/opt/buildhome/repo/pages/events/graphite-and-anodes-2019/location.vue").then(m => m.default || m)
  },
  {
    name: "events-graphite-and-anodes-2019-sponsors",
    path: "/events/graphite-and-anodes-2019/sponsors",
    component: () => import("/opt/buildhome/repo/pages/events/graphite-and-anodes-2019/sponsors.vue").then(m => m.default || m)
  },
  {
    name: "events-graphite2016-agenda",
    path: "/events/graphite2016/agenda",
    component: () => import("/opt/buildhome/repo/pages/events/graphite2016/agenda.vue").then(m => m.default || m)
  },
  {
    name: "events-graphite2016-hosts",
    path: "/events/graphite2016/hosts",
    component: () => import("/opt/buildhome/repo/pages/events/graphite2016/hosts.vue").then(m => m.default || m)
  },
  {
    name: "events-graphite2016",
    path: "/events/graphite2016",
    component: () => import("/opt/buildhome/repo/pages/events/graphite2016/index.vue").then(m => m.default || m)
  },
  {
    name: "events-graphite2016-location",
    path: "/events/graphite2016/location",
    component: () => import("/opt/buildhome/repo/pages/events/graphite2016/location.vue").then(m => m.default || m)
  },
  {
    name: "events-graphite2016-sponsors",
    path: "/events/graphite2016/sponsors",
    component: () => import("/opt/buildhome/repo/pages/events/graphite2016/sponsors.vue").then(m => m.default || m)
  },
  {
    name: "events-graphite2017-agenda",
    path: "/events/graphite2017/agenda",
    component: () => import("/opt/buildhome/repo/pages/events/graphite2017/agenda.vue").then(m => m.default || m)
  },
  {
    name: "events-graphite2017-hosts",
    path: "/events/graphite2017/hosts",
    component: () => import("/opt/buildhome/repo/pages/events/graphite2017/hosts.vue").then(m => m.default || m)
  },
  {
    name: "events-graphite2017",
    path: "/events/graphite2017",
    component: () => import("/opt/buildhome/repo/pages/events/graphite2017/index.vue").then(m => m.default || m)
  },
  {
    name: "events-graphite2017-location",
    path: "/events/graphite2017/location",
    component: () => import("/opt/buildhome/repo/pages/events/graphite2017/location.vue").then(m => m.default || m)
  },
  {
    name: "events-graphite2017-sponsors",
    path: "/events/graphite2017/sponsors",
    component: () => import("/opt/buildhome/repo/pages/events/graphite2017/sponsors.vue").then(m => m.default || m)
  },
  {
    name: "events",
    path: "/events",
    meta: indexjDV0ZxvnYzMeta || {},
    component: () => import("/opt/buildhome/repo/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: "events-nickel-day",
    path: "/events/nickel-day",
    component: () => import("/opt/buildhome/repo/pages/events/nickel-day/index.vue").then(m => m.default || m)
  },
  {
    name: "events-past",
    path: "/events/past",
    meta: pastdZ7VYmohzxMeta || {},
    component: () => import("/opt/buildhome/repo/pages/events/past.vue").then(m => m.default || m)
  },
  {
    name: "events-webinars",
    path: "/events/webinars",
    meta: webinars4SL1J08xY7Meta || {},
    component: () => import("/opt/buildhome/repo/pages/events/webinars.vue").then(m => m.default || m)
  },
  {
    name: "events-world-tour-2018-chicago",
    path: "/events/world-tour-2018/chicago",
    component: () => import("/opt/buildhome/repo/pages/events/world-tour-2018/chicago.vue").then(m => m.default || m)
  },
  {
    name: "events-world-tour-2018-germany",
    path: "/events/world-tour-2018/germany",
    component: () => import("/opt/buildhome/repo/pages/events/world-tour-2018/germany.vue").then(m => m.default || m)
  },
  {
    name: "events-world-tour-2018-hong-kong",
    path: "/events/world-tour-2018/hong-kong",
    component: () => import("/opt/buildhome/repo/pages/events/world-tour-2018/hong-kong.vue").then(m => m.default || m)
  },
  {
    name: "events-world-tour-2018",
    path: "/events/world-tour-2018",
    component: () => import("/opt/buildhome/repo/pages/events/world-tour-2018/index.vue").then(m => m.default || m)
  },
  {
    name: "events-world-tour-2018-london-day-2",
    path: "/events/world-tour-2018/london-day-2",
    component: () => import("/opt/buildhome/repo/pages/events/world-tour-2018/london-day-2.vue").then(m => m.default || m)
  },
  {
    name: "events-world-tour-2018-london",
    path: "/events/world-tour-2018/london",
    component: () => import("/opt/buildhome/repo/pages/events/world-tour-2018/london.vue").then(m => m.default || m)
  },
  {
    name: "events-world-tour-2018-melbourne",
    path: "/events/world-tour-2018/melbourne",
    component: () => import("/opt/buildhome/repo/pages/events/world-tour-2018/melbourne.vue").then(m => m.default || m)
  },
  {
    name: "events-world-tour-2018-new-york",
    path: "/events/world-tour-2018/new-york",
    component: () => import("/opt/buildhome/repo/pages/events/world-tour-2018/new-york.vue").then(m => m.default || m)
  },
  {
    name: "events-world-tour-2018-perth",
    path: "/events/world-tour-2018/perth",
    component: () => import("/opt/buildhome/repo/pages/events/world-tour-2018/perth.vue").then(m => m.default || m)
  },
  {
    name: "events-world-tour-2018-san-francisco",
    path: "/events/world-tour-2018/san-francisco",
    component: () => import("/opt/buildhome/repo/pages/events/world-tour-2018/san-francisco.vue").then(m => m.default || m)
  },
  {
    name: "events-world-tour-2018-seoul",
    path: "/events/world-tour-2018/seoul",
    component: () => import("/opt/buildhome/repo/pages/events/world-tour-2018/seoul.vue").then(m => m.default || m)
  },
  {
    name: "events-world-tour-2018-sydney",
    path: "/events/world-tour-2018/sydney",
    component: () => import("/opt/buildhome/repo/pages/events/world-tour-2018/sydney.vue").then(m => m.default || m)
  },
  {
    name: "events-world-tour-2018-tokyo",
    path: "/events/world-tour-2018/tokyo",
    component: () => import("/opt/buildhome/repo/pages/events/world-tour-2018/tokyo.vue").then(m => m.default || m)
  },
  {
    name: "events-world-tour-2018-toronto",
    path: "/events/world-tour-2018/toronto",
    component: () => import("/opt/buildhome/repo/pages/events/world-tour-2018/toronto.vue").then(m => m.default || m)
  },
  {
    name: "events-world-tour-2018-vancouver",
    path: "/events/world-tour-2018/vancouver",
    component: () => import("/opt/buildhome/repo/pages/events/world-tour-2018/vancouver.vue").then(m => m.default || m)
  },
  {
    name: "events-world-tour-2019-adelaide",
    path: "/events/world-tour-2019/adelaide",
    component: () => import("/opt/buildhome/repo/pages/events/world-tour-2019/adelaide.vue").then(m => m.default || m)
  },
  {
    name: "events-world-tour-2019-cape-town",
    path: "/events/world-tour-2019/cape-town",
    component: () => import("/opt/buildhome/repo/pages/events/world-tour-2019/cape-town.vue").then(m => m.default || m)
  },
  {
    name: "events-world-tour-2019-chicago",
    path: "/events/world-tour-2019/chicago",
    component: () => import("/opt/buildhome/repo/pages/events/world-tour-2019/chicago.vue").then(m => m.default || m)
  },
  {
    name: "events-world-tour-2019-germany",
    path: "/events/world-tour-2019/germany",
    component: () => import("/opt/buildhome/repo/pages/events/world-tour-2019/germany.vue").then(m => m.default || m)
  },
  {
    name: "events-world-tour-2019-hong-kong",
    path: "/events/world-tour-2019/hong-kong",
    component: () => import("/opt/buildhome/repo/pages/events/world-tour-2019/hong-kong.vue").then(m => m.default || m)
  },
  {
    name: "events-world-tour-2019",
    path: "/events/world-tour-2019",
    component: () => import("/opt/buildhome/repo/pages/events/world-tour-2019/index.vue").then(m => m.default || m)
  },
  {
    name: "events-world-tour-2019-london",
    path: "/events/world-tour-2019/london",
    component: () => import("/opt/buildhome/repo/pages/events/world-tour-2019/london.vue").then(m => m.default || m)
  },
  {
    name: "events-world-tour-2019-melbourne",
    path: "/events/world-tour-2019/melbourne",
    component: () => import("/opt/buildhome/repo/pages/events/world-tour-2019/melbourne.vue").then(m => m.default || m)
  },
  {
    name: "events-world-tour-2019-new-york-2",
    path: "/events/world-tour-2019/new-york-2",
    component: () => import("/opt/buildhome/repo/pages/events/world-tour-2019/new-york-2.vue").then(m => m.default || m)
  },
  {
    name: "events-world-tour-2019-new-york",
    path: "/events/world-tour-2019/new-york",
    component: () => import("/opt/buildhome/repo/pages/events/world-tour-2019/new-york.vue").then(m => m.default || m)
  },
  {
    name: "events-world-tour-2019-perth",
    path: "/events/world-tour-2019/perth",
    component: () => import("/opt/buildhome/repo/pages/events/world-tour-2019/perth.vue").then(m => m.default || m)
  },
  {
    name: "events-world-tour-2019-san-francisco",
    path: "/events/world-tour-2019/san-francisco",
    component: () => import("/opt/buildhome/repo/pages/events/world-tour-2019/san-francisco.vue").then(m => m.default || m)
  },
  {
    name: "events-world-tour-2019-seoul",
    path: "/events/world-tour-2019/seoul",
    component: () => import("/opt/buildhome/repo/pages/events/world-tour-2019/seoul.vue").then(m => m.default || m)
  },
  {
    name: "events-world-tour-2019-sydney",
    path: "/events/world-tour-2019/sydney",
    component: () => import("/opt/buildhome/repo/pages/events/world-tour-2019/sydney.vue").then(m => m.default || m)
  },
  {
    name: "events-world-tour-2019-tokyo",
    path: "/events/world-tour-2019/tokyo",
    component: () => import("/opt/buildhome/repo/pages/events/world-tour-2019/tokyo.vue").then(m => m.default || m)
  },
  {
    name: "events-world-tour-2019-toronto",
    path: "/events/world-tour-2019/toronto",
    component: () => import("/opt/buildhome/repo/pages/events/world-tour-2019/toronto.vue").then(m => m.default || m)
  },
  {
    name: "events-world-tour-2019-vancouver",
    path: "/events/world-tour-2019/vancouver",
    component: () => import("/opt/buildhome/repo/pages/events/world-tour-2019/vancouver.vue").then(m => m.default || m)
  },
  {
    name: "events-world-tour-2020",
    path: "/events/world-tour-2020",
    component: () => import("/opt/buildhome/repo/pages/events/world-tour-2020/index.vue").then(m => m.default || m)
  },
  {
    name: "events-world-tour-2022-slug",
    path: "/events/world-tour-2022/:slug()",
    component: () => import("/opt/buildhome/repo/pages/events/world-tour-2022/[slug].vue").then(m => m.default || m)
  },
  {
    name: "events-world-tour-2022",
    path: "/events/world-tour-2022",
    component: () => import("/opt/buildhome/repo/pages/events/world-tour-2022/index.vue").then(m => m.default || m)
  },
  {
    name: "events-world-tour-2023-new-york",
    path: "/events/world-tour-2023-new-york",
    component: () => import("/opt/buildhome/repo/pages/events/world-tour-2023-new-york/index.vue").then(m => m.default || m)
  },
  {
    name: "events-world-tour-online",
    path: "/events/world-tour-online",
    component: () => import("/opt/buildhome/repo/pages/events/world-tour-online/index.vue").then(m => m.default || m)
  },
  {
    name: "events-world-tour",
    path: "/events/world-tour",
    meta: world_45tourx96qJG9EWgMeta || {},
    component: () => import("/opt/buildhome/repo/pages/events/world-tour.vue").then(m => m.default || m)
  },
  {
    name: "events-worldtour-2015-canada",
    path: "/events/worldtour-2015/canada",
    component: () => import("/opt/buildhome/repo/pages/events/worldtour-2015/canada.vue").then(m => m.default || m)
  },
  {
    name: "events-worldtour-2015-hongkong",
    path: "/events/worldtour-2015/hongkong",
    component: () => import("/opt/buildhome/repo/pages/events/worldtour-2015/hongkong.vue").then(m => m.default || m)
  },
  {
    name: "events-worldtour-2015",
    path: "/events/worldtour-2015",
    component: () => import("/opt/buildhome/repo/pages/events/worldtour-2015/index.vue").then(m => m.default || m)
  },
  {
    name: "events-worldtour-2015-london",
    path: "/events/worldtour-2015/london",
    component: () => import("/opt/buildhome/repo/pages/events/worldtour-2015/london.vue").then(m => m.default || m)
  },
  {
    name: "events-worldtour-2015-melbourne",
    path: "/events/worldtour-2015/melbourne",
    component: () => import("/opt/buildhome/repo/pages/events/worldtour-2015/melbourne.vue").then(m => m.default || m)
  },
  {
    name: "events-worldtour-2015-newyork",
    path: "/events/worldtour-2015/newyork",
    component: () => import("/opt/buildhome/repo/pages/events/worldtour-2015/newyork.vue").then(m => m.default || m)
  },
  {
    name: "events-worldtour-2015-sydney",
    path: "/events/worldtour-2015/sydney",
    component: () => import("/opt/buildhome/repo/pages/events/worldtour-2015/sydney.vue").then(m => m.default || m)
  },
  {
    name: "events-worldtour-2015-tokyo",
    path: "/events/worldtour-2015/tokyo",
    component: () => import("/opt/buildhome/repo/pages/events/worldtour-2015/tokyo.vue").then(m => m.default || m)
  },
  {
    name: "events-worldtour-2015-toronto",
    path: "/events/worldtour-2015/toronto",
    component: () => import("/opt/buildhome/repo/pages/events/worldtour-2015/toronto.vue").then(m => m.default || m)
  },
  {
    name: "events-worldtour-2016-canada",
    path: "/events/worldtour-2016/canada",
    component: () => import("/opt/buildhome/repo/pages/events/worldtour-2016/canada.vue").then(m => m.default || m)
  },
  {
    name: "events-worldtour-2016-germany",
    path: "/events/worldtour-2016/germany",
    component: () => import("/opt/buildhome/repo/pages/events/worldtour-2016/germany.vue").then(m => m.default || m)
  },
  {
    name: "events-worldtour-2016-hongkong",
    path: "/events/worldtour-2016/hongkong",
    component: () => import("/opt/buildhome/repo/pages/events/worldtour-2016/hongkong.vue").then(m => m.default || m)
  },
  {
    name: "events-worldtour-2016",
    path: "/events/worldtour-2016",
    component: () => import("/opt/buildhome/repo/pages/events/worldtour-2016/index.vue").then(m => m.default || m)
  },
  {
    name: "events-worldtour-2016-london",
    path: "/events/worldtour-2016/london",
    component: () => import("/opt/buildhome/repo/pages/events/worldtour-2016/london.vue").then(m => m.default || m)
  },
  {
    name: "events-worldtour-2016-melbourne",
    path: "/events/worldtour-2016/melbourne",
    component: () => import("/opt/buildhome/repo/pages/events/worldtour-2016/melbourne.vue").then(m => m.default || m)
  },
  {
    name: "events-worldtour-2016-newyork",
    path: "/events/worldtour-2016/newyork",
    component: () => import("/opt/buildhome/repo/pages/events/worldtour-2016/newyork.vue").then(m => m.default || m)
  },
  {
    name: "events-worldtour-2016-silicon",
    path: "/events/worldtour-2016/silicon",
    component: () => import("/opt/buildhome/repo/pages/events/worldtour-2016/silicon.vue").then(m => m.default || m)
  },
  {
    name: "events-worldtour-2016-sydney",
    path: "/events/worldtour-2016/sydney",
    component: () => import("/opt/buildhome/repo/pages/events/worldtour-2016/sydney.vue").then(m => m.default || m)
  },
  {
    name: "events-worldtour-2016-tokyo",
    path: "/events/worldtour-2016/tokyo",
    component: () => import("/opt/buildhome/repo/pages/events/worldtour-2016/tokyo.vue").then(m => m.default || m)
  },
  {
    name: "events-worldtour-2016-toronto",
    path: "/events/worldtour-2016/toronto",
    component: () => import("/opt/buildhome/repo/pages/events/worldtour-2016/toronto.vue").then(m => m.default || m)
  },
  {
    name: "events-worldtour-2016-washington",
    path: "/events/worldtour-2016/washington",
    component: () => import("/opt/buildhome/repo/pages/events/worldtour-2016/washington.vue").then(m => m.default || m)
  },
  {
    name: "events-worldtour2017-beijing",
    path: "/events/worldtour2017/beijing",
    component: () => import("/opt/buildhome/repo/pages/events/worldtour2017/beijing.vue").then(m => m.default || m)
  },
  {
    name: "events-worldtour2017-germany",
    path: "/events/worldtour2017/germany",
    component: () => import("/opt/buildhome/repo/pages/events/worldtour2017/germany.vue").then(m => m.default || m)
  },
  {
    name: "events-worldtour2017-hongkong",
    path: "/events/worldtour2017/hongkong",
    component: () => import("/opt/buildhome/repo/pages/events/worldtour2017/hongkong.vue").then(m => m.default || m)
  },
  {
    name: "events-worldtour2017",
    path: "/events/worldtour2017",
    component: () => import("/opt/buildhome/repo/pages/events/worldtour2017/index.vue").then(m => m.default || m)
  },
  {
    name: "events-worldtour2017-london",
    path: "/events/worldtour2017/london",
    component: () => import("/opt/buildhome/repo/pages/events/worldtour2017/london.vue").then(m => m.default || m)
  },
  {
    name: "events-worldtour2017-melbourne",
    path: "/events/worldtour2017/melbourne",
    component: () => import("/opt/buildhome/repo/pages/events/worldtour2017/melbourne.vue").then(m => m.default || m)
  },
  {
    name: "events-worldtour2017-newyork",
    path: "/events/worldtour2017/newyork",
    component: () => import("/opt/buildhome/repo/pages/events/worldtour2017/newyork.vue").then(m => m.default || m)
  },
  {
    name: "events-worldtour2017-perth",
    path: "/events/worldtour2017/perth",
    component: () => import("/opt/buildhome/repo/pages/events/worldtour2017/perth.vue").then(m => m.default || m)
  },
  {
    name: "events-worldtour2017-seoul",
    path: "/events/worldtour2017/seoul",
    component: () => import("/opt/buildhome/repo/pages/events/worldtour2017/seoul.vue").then(m => m.default || m)
  },
  {
    name: "events-worldtour2017-sydney",
    path: "/events/worldtour2017/sydney",
    component: () => import("/opt/buildhome/repo/pages/events/worldtour2017/sydney.vue").then(m => m.default || m)
  },
  {
    name: "events-worldtour2017-tokyo",
    path: "/events/worldtour2017/tokyo",
    component: () => import("/opt/buildhome/repo/pages/events/worldtour2017/tokyo.vue").then(m => m.default || m)
  },
  {
    name: "events-worldtour2017-toronto",
    path: "/events/worldtour2017/toronto",
    component: () => import("/opt/buildhome/repo/pages/events/worldtour2017/toronto.vue").then(m => m.default || m)
  },
  {
    name: "events-worldtour2017-vancouver",
    path: "/events/worldtour2017/vancouver",
    component: () => import("/opt/buildhome/repo/pages/events/worldtour2017/vancouver.vue").then(m => m.default || m)
  },
  {
    name: "events-worldtour2017-washingtondc",
    path: "/events/worldtour2017/washingtondc",
    component: () => import("/opt/buildhome/repo/pages/events/worldtour2017/washingtondc.vue").then(m => m.default || m)
  },
  {
    name: "events-worldtour2017-zurich",
    path: "/events/worldtour2017/zurich",
    component: () => import("/opt/buildhome/repo/pages/events/worldtour2017/zurich.vue").then(m => m.default || m)
  },
  {
    name: "excel-add-in",
    path: "/excel-add-in",
    component: () => import("/opt/buildhome/repo/pages/excel-add-in.vue").then(m => m.default || m)
  },
  {
    name: "fluorspar-slug",
    path: "/fluorspar/:slug()",
    component: () => import("/opt/buildhome/repo/pages/fluorspar/[slug].vue").then(m => m.default || m)
  },
  {
    name: "fluorspar-data-reports-slug",
    path: "/fluorspar/data-reports/:slug()",
    meta: _91slug_93HF760RRQB3Meta || {},
    component: () => import("/opt/buildhome/repo/pages/fluorspar/data-reports/[slug].vue").then(m => m.default || m)
  },
  {
    name: "fluorspar-data-reports-forecast-video-id",
    path: "/fluorspar/data-reports/forecast/video/:id()",
    component: () => import("/opt/buildhome/repo/pages/fluorspar/data-reports/forecast/video/[id].vue").then(m => m.default || m)
  },
  {
    name: "fluorspar",
    path: "/fluorspar",
    meta: indexI6YuS0AXdnMeta || {},
    component: () => import("/opt/buildhome/repo/pages/fluorspar/index.vue").then(m => m.default || m)
  },
  {
    name: "fluorspar-market-insights-slug",
    path: "/fluorspar/market-insights/:slug()",
    meta: _91slug_932n9wtfrMoEMeta || {},
    component: () => import("/opt/buildhome/repo/pages/fluorspar/market-insights/[slug].vue").then(m => m.default || m)
  },
  {
    name: "forecasts",
    path: "/forecasts",
    meta: forecastsWqQWc0MH9zMeta || {},
    component: () => import("/opt/buildhome/repo/pages/forecasts.vue").then(m => m.default || m)
  },
  {
    name: "forms-stellantis",
    path: "/forms/stellantis",
    component: () => import("/opt/buildhome/repo/pages/forms/stellantis.vue").then(m => m.default || m)
  },
  {
    name: "independent-practitioners-assurance-report",
    path: "/independent-practitioners-assurance-report",
    component: () => import("/opt/buildhome/repo/pages/independent-practitioners-assurance-report.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/buildhome/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "industry-reports",
    path: "/industry-reports",
    component: () => import("/opt/buildhome/repo/pages/industry-reports.vue").then(m => m.default || m)
  },
  {
    name: "join-us",
    path: "/join-us",
    component: () => import("/opt/buildhome/repo/pages/join-us.vue").then(m => m.default || m)
  },
  {
    name: "lca",
    path: "/lca",
    component: () => import("/opt/buildhome/repo/pages/lca.vue").then(m => m.default || m)
  },
  {
    name: "lithium-ion-batteries-slug",
    path: "/lithium-ion-batteries/:slug()",
    component: () => import("/opt/buildhome/repo/pages/lithium-ion-batteries/[slug].vue").then(m => m.default || m)
  },
  {
    name: "lithium-ion-batteries-capacity-database-slug",
    path: "/lithium-ion-batteries/capacity-database/:slug()",
    meta: _91slug_93Dk36JDjKYPMeta || {},
    component: () => import("/opt/buildhome/repo/pages/lithium-ion-batteries/capacity-database/[slug].vue").then(m => m.default || m)
  },
  {
    name: "lithium-ion-batteries-data-reports-slug",
    path: "/lithium-ion-batteries/data-reports/:slug()",
    meta: _91slug_93fCXI8Kkj6SMeta || {},
    component: () => import("/opt/buildhome/repo/pages/lithium-ion-batteries/data-reports/[slug].vue").then(m => m.default || m)
  },
  {
    name: "lithium-ion-batteries-data-reports-forecast-video-id",
    path: "/lithium-ion-batteries/data-reports/forecast/video/:id()",
    component: () => import("/opt/buildhome/repo/pages/lithium-ion-batteries/data-reports/forecast/video/[id].vue").then(m => m.default || m)
  },
  {
    name: "lithium-ion-batteries-data-reports-market-assessments-report-id",
    path: "/lithium-ion-batteries/data-reports/market-assessments/report/:id()",
    component: () => import("/opt/buildhome/repo/pages/lithium-ion-batteries/data-reports/market-assessments/report/[id].vue").then(m => m.default || m)
  },
  {
    name: "lithium-ion-batteries-data-reports-market-assessments-video-id",
    path: "/lithium-ion-batteries/data-reports/market-assessments/video/:id()",
    component: () => import("/opt/buildhome/repo/pages/lithium-ion-batteries/data-reports/market-assessments/video/[id].vue").then(m => m.default || m)
  },
  {
    name: "lithium-ion-batteries-data-reports-price-assessments-report-id",
    path: "/lithium-ion-batteries/data-reports/price-assessments/report/:id()",
    component: () => import("/opt/buildhome/repo/pages/lithium-ion-batteries/data-reports/price-assessments/report/[id].vue").then(m => m.default || m)
  },
  {
    name: "lithium-ion-batteries-data-reports-price-assessments-video-id",
    path: "/lithium-ion-batteries/data-reports/price-assessments/video/:id()",
    meta: _91id_93bnTFu7Cso5Meta || {},
    component: () => import("/opt/buildhome/repo/pages/lithium-ion-batteries/data-reports/price-assessments/video/[id].vue").then(m => m.default || m)
  },
  {
    name: "lithium-ion-batteries",
    path: "/lithium-ion-batteries",
    meta: indexGxu7xHcGAPMeta || {},
    component: () => import("/opt/buildhome/repo/pages/lithium-ion-batteries/index.vue").then(m => m.default || m)
  },
  {
    name: "lithium-ion-batteries-lithium-ion-batteries-prices-slug",
    path: "/lithium-ion-batteries/lithium-ion-batteries-prices/:slug()",
    meta: _91slug_93fYooskxAB4Meta || {},
    component: () => import("/opt/buildhome/repo/pages/lithium-ion-batteries/lithium-ion-batteries-prices/[slug].vue").then(m => m.default || m)
  },
  {
    name: "lithium-ion-batteries-market-insights-slug",
    path: "/lithium-ion-batteries/market-insights/:slug()",
    meta: _91slug_93TZAZGL4LLnMeta || {},
    component: () => import("/opt/buildhome/repo/pages/lithium-ion-batteries/market-insights/[slug].vue").then(m => m.default || m)
  },
  {
    name: "lithium-ion-battery-prices",
    path: "/lithium-ion-battery-prices",
    component: () => import("/opt/buildhome/repo/pages/lithium-ion-battery-prices.vue").then(m => m.default || m)
  },
  {
    name: "lithium-ion-battery-raw-material-index",
    path: "/lithium-ion-battery-raw-material-index",
    component: () => import("/opt/buildhome/repo/pages/lithium-ion-battery-raw-material-index.vue").then(m => m.default || m)
  },
  {
    name: "lithium-price-assessment-portal-slug",
    path: "/lithium-price-assessment-portal/:slug()",
    component: () => import("/opt/buildhome/repo/pages/lithium-price-assessment-portal/[slug].vue").then(m => m.default || m)
  },
  {
    name: "lithium-prices",
    path: "/lithium-prices",
    component: () => import("/opt/buildhome/repo/pages/lithium-prices.vue").then(m => m.default || m)
  },
  {
    name: "lithium-slug",
    path: "/lithium/:slug()",
    component: () => import("/opt/buildhome/repo/pages/lithium/[slug].vue").then(m => m.default || m)
  },
  {
    name: "lithium-data-reports-slug",
    path: "/lithium/data-reports/:slug()",
    meta: _91slug_93UxpUB4jQWMMeta || {},
    component: () => import("/opt/buildhome/repo/pages/lithium/data-reports/[slug].vue").then(m => m.default || m)
  },
  {
    name: "lithium-data-reports-forecast-report-id",
    path: "/lithium/data-reports/forecast/report/:id()",
    component: () => import("/opt/buildhome/repo/pages/lithium/data-reports/forecast/report/[id].vue").then(m => m.default || m)
  },
  {
    name: "lithium-data-reports-forecast-video-id",
    path: "/lithium/data-reports/forecast/video/:id()",
    meta: _91id_93fmAFBXScABMeta || {},
    component: () => import("/opt/buildhome/repo/pages/lithium/data-reports/forecast/video/[id].vue").then(m => m.default || m)
  },
  {
    name: "lithium-data-reports-price-assessments-report-id",
    path: "/lithium/data-reports/price-assessments/report/:id()",
    meta: _91id_93j3VKrmy2FlMeta || {},
    component: () => import("/opt/buildhome/repo/pages/lithium/data-reports/price-assessments/report/[id].vue").then(m => m.default || m)
  },
  {
    name: "lithium-data-reports-price-assessments-video-id",
    path: "/lithium/data-reports/price-assessments/video/:id()",
    component: () => import("/opt/buildhome/repo/pages/lithium/data-reports/price-assessments/video/[id].vue").then(m => m.default || m)
  },
  {
    name: "lithium-data-reports-sustainability-video-id",
    path: "/lithium/data-reports/sustainability/video/:id()",
    component: () => import("/opt/buildhome/repo/pages/lithium/data-reports/sustainability/video/[id].vue").then(m => m.default || m)
  },
  {
    name: "lithium",
    path: "/lithium",
    meta: index9Oz1g1h8rZMeta || {},
    component: () => import("/opt/buildhome/repo/pages/lithium/index.vue").then(m => m.default || m)
  },
  {
    name: "lithium-lithium-prices-slug",
    path: "/lithium/lithium-prices/:slug()",
    meta: _91slug_93Y9YgWyRJY3Meta || {},
    component: () => import("/opt/buildhome/repo/pages/lithium/lithium-prices/[slug].vue").then(m => m.default || m)
  },
  {
    name: "lithium-market-insights-slug",
    path: "/lithium/market-insights/:slug()",
    meta: _91slug_93zbjItBZHVtMeta || {},
    component: () => import("/opt/buildhome/repo/pages/lithium/market-insights/[slug].vue").then(m => m.default || m)
  },
  {
    name: "lithium-sustainability-slug",
    path: "/lithium/sustainability/:slug()",
    meta: _91slug_939qKP4XJPGoMeta || {},
    component: () => import("/opt/buildhome/repo/pages/lithium/sustainability/[slug].vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/opt/buildhome/repo/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "manganese-slug",
    path: "/manganese/:slug()",
    component: () => import("/opt/buildhome/repo/pages/manganese/[slug].vue").then(m => m.default || m)
  },
  {
    name: "manganese-data-reports-slug",
    path: "/manganese/data-reports/:slug()",
    meta: _91slug_93uJGvzAt1K8Meta || {},
    component: () => import("/opt/buildhome/repo/pages/manganese/data-reports/[slug].vue").then(m => m.default || m)
  },
  {
    name: "manganese-data-reports-forecast-video-id",
    path: "/manganese/data-reports/forecast/video/:id()",
    component: () => import("/opt/buildhome/repo/pages/manganese/data-reports/forecast/video/[id].vue").then(m => m.default || m)
  },
  {
    name: "manganese",
    path: "/manganese",
    meta: index54AsFA2d51Meta || {},
    component: () => import("/opt/buildhome/repo/pages/manganese/index.vue").then(m => m.default || m)
  },
  {
    name: "manganese-market-insights-slug",
    path: "/manganese/market-insights/:slug()",
    meta: _91slug_93d1XmpkuTQFMeta || {},
    component: () => import("/opt/buildhome/repo/pages/manganese/market-insights/[slug].vue").then(m => m.default || m)
  },
  {
    name: "market-assessments",
    path: "/market-assessments",
    meta: market_45assessmentsLZygAaQAt2Meta || {},
    component: () => import("/opt/buildhome/repo/pages/market-assessments.vue").then(m => m.default || m)
  },
  {
    name: "membership-slug",
    path: "/membership/:slug()",
    component: () => import("/opt/buildhome/repo/pages/membership/[slug].vue").then(m => m.default || m)
  },
  {
    name: "methodologies",
    path: "/methodologies",
    component: () => import("/opt/buildhome/repo/pages/methodologies/index.vue").then(m => m.default || m)
  },
  {
    name: "methodologies-market-assessments-gigafactories",
    path: "/methodologies/market-assessments/gigafactories",
    component: () => import("/opt/buildhome/repo/pages/methodologies/market-assessments/gigafactories.vue").then(m => m.default || m)
  },
  {
    name: "mineral-pricing-chart",
    path: "/mineral-pricing-chart",
    component: () => import("/opt/buildhome/repo/pages/mineral-pricing-chart.vue").then(m => m.default || m)
  },
  {
    name: "natural-graphite-slug",
    path: "/natural-graphite/:slug()",
    component: () => import("/opt/buildhome/repo/pages/natural-graphite/[slug].vue").then(m => m.default || m)
  },
  {
    name: "natural-graphite-data-reports-slug",
    path: "/natural-graphite/data-reports/:slug()",
    meta: _91slug_93aMjdEaukH2Meta || {},
    component: () => import("/opt/buildhome/repo/pages/natural-graphite/data-reports/[slug].vue").then(m => m.default || m)
  },
  {
    name: "natural-graphite-data-reports-forecast-video-id",
    path: "/natural-graphite/data-reports/forecast/video/:id()",
    component: () => import("/opt/buildhome/repo/pages/natural-graphite/data-reports/forecast/video/[id].vue").then(m => m.default || m)
  },
  {
    name: "natural-graphite-data-reports-price-assessments-report-id",
    path: "/natural-graphite/data-reports/price-assessments/report/:id()",
    meta: _91id_93wj7B8NrLkLMeta || {},
    component: () => import("/opt/buildhome/repo/pages/natural-graphite/data-reports/price-assessments/report/[id].vue").then(m => m.default || m)
  },
  {
    name: "natural-graphite-data-reports-price-assessments-video-id",
    path: "/natural-graphite/data-reports/price-assessments/video/:id()",
    component: () => import("/opt/buildhome/repo/pages/natural-graphite/data-reports/price-assessments/video/[id].vue").then(m => m.default || m)
  },
  {
    name: "natural-graphite-data-reports-sustainability-video-id",
    path: "/natural-graphite/data-reports/sustainability/video/:id()",
    meta: _91id_93KDNVF1vzItMeta || {},
    component: () => import("/opt/buildhome/repo/pages/natural-graphite/data-reports/sustainability/video/[id].vue").then(m => m.default || m)
  },
  {
    name: "natural-graphite",
    path: "/natural-graphite",
    meta: indexeD23UhSKGEMeta || {},
    component: () => import("/opt/buildhome/repo/pages/natural-graphite/index.vue").then(m => m.default || m)
  },
  {
    name: "natural-graphite-market-insights-slug",
    path: "/natural-graphite/market-insights/:slug()",
    meta: _91slug_93s2zwPnnLwDMeta || {},
    component: () => import("/opt/buildhome/repo/pages/natural-graphite/market-insights/[slug].vue").then(m => m.default || m)
  },
  {
    name: "natural-graphite-natural-graphite-prices-slug",
    path: "/natural-graphite/natural-graphite-prices/:slug()",
    meta: _91slug_936v79G5C5CoMeta || {},
    component: () => import("/opt/buildhome/repo/pages/natural-graphite/natural-graphite-prices/[slug].vue").then(m => m.default || m)
  },
  {
    name: "natural-graphite-sustainability-slug",
    path: "/natural-graphite/sustainability/:slug()",
    meta: _91slug_934ei19UR1UbMeta || {},
    component: () => import("/opt/buildhome/repo/pages/natural-graphite/sustainability/[slug].vue").then(m => m.default || m)
  },
  {
    name: "newsletter-signup",
    path: "/newsletter-signup",
    component: () => import("/opt/buildhome/repo/pages/newsletter-signup.vue").then(m => m.default || m)
  },
  {
    name: "nickel-slug",
    path: "/nickel/:slug()",
    component: () => import("/opt/buildhome/repo/pages/nickel/[slug].vue").then(m => m.default || m)
  },
  {
    name: "nickel-data-reports-slug",
    path: "/nickel/data-reports/:slug()",
    meta: _91slug_93gKV4YsSk2zMeta || {},
    component: () => import("/opt/buildhome/repo/pages/nickel/data-reports/[slug].vue").then(m => m.default || m)
  },
  {
    name: "nickel-data-reports-forecast-report-id",
    path: "/nickel/data-reports/forecast/report/:id()",
    component: () => import("/opt/buildhome/repo/pages/nickel/data-reports/forecast/report/[id].vue").then(m => m.default || m)
  },
  {
    name: "nickel-data-reports-forecast-video-id",
    path: "/nickel/data-reports/forecast/video/:id()",
    component: () => import("/opt/buildhome/repo/pages/nickel/data-reports/forecast/video/[id].vue").then(m => m.default || m)
  },
  {
    name: "nickel-data-reports-price-assessments-report-id",
    path: "/nickel/data-reports/price-assessments/report/:id()",
    meta: _91id_93uPsAmLKwnJMeta || {},
    component: () => import("/opt/buildhome/repo/pages/nickel/data-reports/price-assessments/report/[id].vue").then(m => m.default || m)
  },
  {
    name: "nickel-data-reports-price-assessments-video-id",
    path: "/nickel/data-reports/price-assessments/video/:id()",
    component: () => import("/opt/buildhome/repo/pages/nickel/data-reports/price-assessments/video/[id].vue").then(m => m.default || m)
  },
  {
    name: "nickel-data-reports-sustainability-video-id",
    path: "/nickel/data-reports/sustainability/video/:id()",
    meta: _91id_93sRJxzYi9efMeta || {},
    component: () => import("/opt/buildhome/repo/pages/nickel/data-reports/sustainability/video/[id].vue").then(m => m.default || m)
  },
  {
    name: "nickel",
    path: "/nickel",
    meta: indexkC9jVE9k93Meta || {},
    component: () => import("/opt/buildhome/repo/pages/nickel/index.vue").then(m => m.default || m)
  },
  {
    name: "nickel-market-insights-slug",
    path: "/nickel/market-insights/:slug()",
    meta: _91slug_93nTrGiTk7hRMeta || {},
    component: () => import("/opt/buildhome/repo/pages/nickel/market-insights/[slug].vue").then(m => m.default || m)
  },
  {
    name: "nickel-nickel-prices-slug",
    path: "/nickel/nickel-prices/:slug()",
    meta: _91slug_93JCadwomZa0Meta || {},
    component: () => import("/opt/buildhome/repo/pages/nickel/nickel-prices/[slug].vue").then(m => m.default || m)
  },
  {
    name: "nickel-sustainability-slug",
    path: "/nickel/sustainability/:slug()",
    meta: _91slug_935WRuBVYGmuMeta || {},
    component: () => import("/opt/buildhome/repo/pages/nickel/sustainability/[slug].vue").then(m => m.default || m)
  },
  {
    name: "phosphate-slug",
    path: "/phosphate/:slug()",
    component: () => import("/opt/buildhome/repo/pages/phosphate/[slug].vue").then(m => m.default || m)
  },
  {
    name: "phosphate-data-reports-slug",
    path: "/phosphate/data-reports/:slug()",
    meta: _91slug_935168CrC6CpMeta || {},
    component: () => import("/opt/buildhome/repo/pages/phosphate/data-reports/[slug].vue").then(m => m.default || m)
  },
  {
    name: "phosphate-data-reports-forecast-video-id",
    path: "/phosphate/data-reports/forecast/video/:id()",
    component: () => import("/opt/buildhome/repo/pages/phosphate/data-reports/forecast/video/[id].vue").then(m => m.default || m)
  },
  {
    name: "phosphate-data-reports-report-id",
    path: "/phosphate/data-reports/report/:id()",
    meta: _91id_93CV6zuFH4a1Meta || {},
    component: () => import("/opt/buildhome/repo/pages/phosphate/data-reports/report/[id].vue").then(m => m.default || m)
  },
  {
    name: "phosphate",
    path: "/phosphate",
    meta: indexKVnlTIdCXzMeta || {},
    component: () => import("/opt/buildhome/repo/pages/phosphate/index.vue").then(m => m.default || m)
  },
  {
    name: "phosphate-market-insights-slug",
    path: "/phosphate/market-insights/:slug()",
    meta: _91slug_93dICUrCiwsIMeta || {},
    component: () => import("/opt/buildhome/repo/pages/phosphate/market-insights/[slug].vue").then(m => m.default || m)
  },
  {
    name: "policy-newsletter",
    path: "/policy-newsletter",
    component: () => import("/opt/buildhome/repo/pages/policy-newsletter.vue").then(m => m.default || m)
  },
  {
    name: "preview-_postType-id",
    path: "/preview/_postType/:id()",
    meta: _91id_93h4oibBAr7rMeta || {},
    component: () => import("/opt/buildhome/repo/pages/preview/_postType/[id].vue").then(m => m.default || m)
  },
  {
    name: "price-assessments-slug-id",
    path: "/price-assessments/:slug()/:id()",
    component: () => import("/opt/buildhome/repo/pages/price-assessments/[slug]/[id].vue").then(m => m.default || m)
  },
  {
    name: "price-assessments-slug-reports-id",
    path: "/price-assessments/:slug()/reports/:id()",
    component: () => import("/opt/buildhome/repo/pages/price-assessments/[slug]/reports/[id].vue").then(m => m.default || m)
  },
  {
    name: "price-assessments",
    path: "/price-assessments",
    meta: indexVnchHRinAMMeta || {},
    component: () => import("/opt/buildhome/repo/pages/price-assessments/index.vue").then(m => m.default || m)
  },
  {
    name: "price-dashboard-chart-builder",
    path: "/price-dashboard/chart-builder",
    meta: chart_45builderuwfe2Hcji0Meta || {},
    component: () => import("/opt/buildhome/repo/pages/price-dashboard/chart-builder.vue").then(m => m.default || m)
  },
  {
    name: "price-dashboard",
    path: "/price-dashboard",
    meta: index8vsS0KUWJUMeta || {},
    component: () => import("/opt/buildhome/repo/pages/price-dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: "price-dashboard-watchlist-id",
    path: "/price-dashboard/watchlist/:id()",
    meta: _91id_93CTA9r8Rz0oMeta || {},
    component: () => import("/opt/buildhome/repo/pages/price-dashboard/watchlist/[id].vue").then(m => m.default || m)
  },
  {
    name: "price-dashboard-watchlist",
    path: "/price-dashboard/watchlist",
    meta: indexxwCInpLYwrMeta || {},
    component: () => import("/opt/buildhome/repo/pages/price-dashboard/watchlist/index.vue").then(m => m.default || m)
  },
  {
    name: "price-spotlight-signup",
    path: "/price-spotlight-signup",
    component: () => import("/opt/buildhome/repo/pages/price-spotlight-signup.vue").then(m => m.default || m)
  },
  {
    name: "privacy",
    path: "/privacy",
    component: () => import("/opt/buildhome/repo/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: "rare-earths-slug",
    path: "/rare-earths/:slug()",
    component: () => import("/opt/buildhome/repo/pages/rare-earths/[slug].vue").then(m => m.default || m)
  },
  {
    name: "rare-earths-data-reports-slug",
    path: "/rare-earths/data-reports/:slug()",
    meta: _91slug_93N10fcNNa4nMeta || {},
    component: () => import("/opt/buildhome/repo/pages/rare-earths/data-reports/[slug].vue").then(m => m.default || m)
  },
  {
    name: "rare-earths-data-reports-forecast-video-id",
    path: "/rare-earths/data-reports/forecast/video/:id()",
    component: () => import("/opt/buildhome/repo/pages/rare-earths/data-reports/forecast/video/[id].vue").then(m => m.default || m)
  },
  {
    name: "rare-earths-data-reports-price-assessments-report-id",
    path: "/rare-earths/data-reports/price-assessments/report/:id()",
    meta: _91id_93BemgRhGmXKMeta || {},
    component: () => import("/opt/buildhome/repo/pages/rare-earths/data-reports/price-assessments/report/[id].vue").then(m => m.default || m)
  },
  {
    name: "rare-earths-data-reports-price-assessments-video-id",
    path: "/rare-earths/data-reports/price-assessments/video/:id()",
    component: () => import("/opt/buildhome/repo/pages/rare-earths/data-reports/price-assessments/video/[id].vue").then(m => m.default || m)
  },
  {
    name: "rare-earths-data-reports-sustainability-video-id",
    path: "/rare-earths/data-reports/sustainability/video/:id()",
    meta: _91id_93j3OxlYYCVEMeta || {},
    component: () => import("/opt/buildhome/repo/pages/rare-earths/data-reports/sustainability/video/[id].vue").then(m => m.default || m)
  },
  {
    name: "rare-earths",
    path: "/rare-earths",
    meta: indexgEXpI3bsM6Meta || {},
    component: () => import("/opt/buildhome/repo/pages/rare-earths/index.vue").then(m => m.default || m)
  },
  {
    name: "rare-earths-market-insights-slug",
    path: "/rare-earths/market-insights/:slug()",
    meta: _91slug_93yue1L2QGc9Meta || {},
    component: () => import("/opt/buildhome/repo/pages/rare-earths/market-insights/[slug].vue").then(m => m.default || m)
  },
  {
    name: "rare-earths-rare-earths-prices-slug",
    path: "/rare-earths/rare-earths-prices/:slug()",
    meta: _91slug_93pABT8zOdoaMeta || {},
    component: () => import("/opt/buildhome/repo/pages/rare-earths/rare-earths-prices/[slug].vue").then(m => m.default || m)
  },
  {
    name: "rare-earths-sustainability-slug",
    path: "/rare-earths/sustainability/:slug()",
    meta: _91slug_93RvWQAPMjvsMeta || {},
    component: () => import("/opt/buildhome/repo/pages/rare-earths/sustainability/[slug].vue").then(m => m.default || m)
  },
  {
    name: "recycling-slug",
    path: "/recycling/:slug()",
    component: () => import("/opt/buildhome/repo/pages/recycling/[slug].vue").then(m => m.default || m)
  },
  {
    name: "recycling-data-reports-slug",
    path: "/recycling/data-reports/:slug()",
    meta: _91slug_93b4ndwMla3wMeta || {},
    component: () => import("/opt/buildhome/repo/pages/recycling/data-reports/[slug].vue").then(m => m.default || m)
  },
  {
    name: "recycling-data-reports-forecast-video-id",
    path: "/recycling/data-reports/forecast/video/:id()",
    component: () => import("/opt/buildhome/repo/pages/recycling/data-reports/forecast/video/[id].vue").then(m => m.default || m)
  },
  {
    name: "recycling-data-reports-price-assessments-report-id",
    path: "/recycling/data-reports/price-assessments/report/:id()",
    meta: _91id_93JT6bUi57fUMeta || {},
    component: () => import("/opt/buildhome/repo/pages/recycling/data-reports/price-assessments/report/[id].vue").then(m => m.default || m)
  },
  {
    name: "recycling-data-reports-price-assessments-video-id",
    path: "/recycling/data-reports/price-assessments/video/:id()",
    component: () => import("/opt/buildhome/repo/pages/recycling/data-reports/price-assessments/video/[id].vue").then(m => m.default || m)
  },
  {
    name: "recycling",
    path: "/recycling",
    meta: indexYD3lNtJcwpMeta || {},
    component: () => import("/opt/buildhome/repo/pages/recycling/index.vue").then(m => m.default || m)
  },
  {
    name: "recycling-market-insights-slug",
    path: "/recycling/market-insights/:slug()",
    meta: _91slug_93zNgyC2zEOJMeta || {},
    component: () => import("/opt/buildhome/repo/pages/recycling/market-insights/[slug].vue").then(m => m.default || m)
  },
  {
    name: "recycling-recycling-prices-slug",
    path: "/recycling/recycling-prices/:slug()",
    meta: _91slug_93CnE3qVIMhLMeta || {},
    component: () => import("/opt/buildhome/repo/pages/recycling/recycling-prices/[slug].vue").then(m => m.default || m)
  },
  {
    name: "request-for-proposal",
    path: "/request-for-proposal",
    component: () => import("/opt/buildhome/repo/pages/request-for-proposal.vue").then(m => m.default || m)
  },
  {
    name: "reset-password",
    path: "/reset-password",
    component: () => import("/opt/buildhome/repo/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: "set-password",
    path: "/set-password",
    component: () => import("/opt/buildhome/repo/pages/set-password.vue").then(m => m.default || m)
  },
  {
    name: "silicon-anode-slug",
    path: "/silicon-anode/:slug()",
    component: () => import("/opt/buildhome/repo/pages/silicon-anode/[slug].vue").then(m => m.default || m)
  },
  {
    name: "silicon-anode-data-reports-slug",
    path: "/silicon-anode/data-reports/:slug()",
    meta: _91slug_93SPpHRj5eZtMeta || {},
    component: () => import("/opt/buildhome/repo/pages/silicon-anode/data-reports/[slug].vue").then(m => m.default || m)
  },
  {
    name: "silicon-anode-data-reports-forecast-video-id",
    path: "/silicon-anode/data-reports/forecast/video/:id()",
    component: () => import("/opt/buildhome/repo/pages/silicon-anode/data-reports/forecast/video/[id].vue").then(m => m.default || m)
  },
  {
    name: "silicon-anode-data-reports-report-id",
    path: "/silicon-anode/data-reports/report/:id()",
    meta: _91id_93CBxd6MSfb5Meta || {},
    component: () => import("/opt/buildhome/repo/pages/silicon-anode/data-reports/report/[id].vue").then(m => m.default || m)
  },
  {
    name: "silicon-anode-data-reports-sustainability-video-id",
    path: "/silicon-anode/data-reports/sustainability/video/:id()",
    component: () => import("/opt/buildhome/repo/pages/silicon-anode/data-reports/sustainability/video/[id].vue").then(m => m.default || m)
  },
  {
    name: "silicon-anode",
    path: "/silicon-anode",
    meta: indexSsQIUyPOObMeta || {},
    component: () => import("/opt/buildhome/repo/pages/silicon-anode/index.vue").then(m => m.default || m)
  },
  {
    name: "silicon-anode-market-insights-slug",
    path: "/silicon-anode/market-insights/:slug()",
    meta: _91slug_93ZqQ2aiNdCRMeta || {},
    component: () => import("/opt/buildhome/repo/pages/silicon-anode/market-insights/[slug].vue").then(m => m.default || m)
  },
  {
    name: "sodium-ion-batteries-capacity-database-slug",
    path: "/sodium-ion-batteries/capacity-database/:slug()",
    meta: _91slug_933z2s4zCBqAMeta || {},
    component: () => import("/opt/buildhome/repo/pages/sodium-ion-batteries/capacity-database/[slug].vue").then(m => m.default || m)
  },
  {
    name: "sodium-ion-batteries-capacity-database",
    path: "/sodium-ion-batteries/capacity-database",
    meta: indexqdcseVYEWFMeta || {},
    component: () => import("/opt/buildhome/repo/pages/sodium-ion-batteries/capacity-database/index.vue").then(m => m.default || m)
  },
  {
    name: "sodium-ion-batteries-data-reports-slug",
    path: "/sodium-ion-batteries/data-reports/:slug()",
    meta: _91slug_93eU3AZtsRr2Meta || {},
    component: () => import("/opt/buildhome/repo/pages/sodium-ion-batteries/data-reports/[slug].vue").then(m => m.default || m)
  },
  {
    name: "sodium-ion-batteries-data-reports-forecast-video-id",
    path: "/sodium-ion-batteries/data-reports/forecast/video/:id()",
    component: () => import("/opt/buildhome/repo/pages/sodium-ion-batteries/data-reports/forecast/video/[id].vue").then(m => m.default || m)
  },
  {
    name: "sodium-ion-batteries-data-reports-market-assessments-report-id",
    path: "/sodium-ion-batteries/data-reports/market-assessments/report/:id()",
    component: () => import("/opt/buildhome/repo/pages/sodium-ion-batteries/data-reports/market-assessments/report/[id].vue").then(m => m.default || m)
  },
  {
    name: "sodium-ion-batteries-data-reports-market-assessments-video-id",
    path: "/sodium-ion-batteries/data-reports/market-assessments/video/:id()",
    component: () => import("/opt/buildhome/repo/pages/sodium-ion-batteries/data-reports/market-assessments/video/[id].vue").then(m => m.default || m)
  },
  {
    name: "sodium-ion-batteries-data-reports-report-id",
    path: "/sodium-ion-batteries/data-reports/report/:id()",
    meta: _91id_93tUbavR9xo0Meta || {},
    component: () => import("/opt/buildhome/repo/pages/sodium-ion-batteries/data-reports/report/[id].vue").then(m => m.default || m)
  },
  {
    name: "sodium-ion-batteries",
    path: "/sodium-ion-batteries",
    meta: indexnvEi1iGIDVMeta || {},
    component: () => import("/opt/buildhome/repo/pages/sodium-ion-batteries/index.vue").then(m => m.default || m)
  },
  {
    name: "sodium-ion-batteries-market-insights-slug",
    path: "/sodium-ion-batteries/market-insights/:slug()",
    meta: _91slug_935u3uP5AuNzMeta || {},
    component: () => import("/opt/buildhome/repo/pages/sodium-ion-batteries/market-insights/[slug].vue").then(m => m.default || m)
  },
  {
    name: "solid-state-slug",
    path: "/solid-state/:slug()",
    component: () => import("/opt/buildhome/repo/pages/solid-state/[slug].vue").then(m => m.default || m)
  },
  {
    name: "solid-state-data-reports-slug",
    path: "/solid-state/data-reports/:slug()",
    meta: _91slug_93xgPuqImAfNMeta || {},
    component: () => import("/opt/buildhome/repo/pages/solid-state/data-reports/[slug].vue").then(m => m.default || m)
  },
  {
    name: "solid-state-data-reports-forecast-video-id",
    path: "/solid-state/data-reports/forecast/video/:id()",
    component: () => import("/opt/buildhome/repo/pages/solid-state/data-reports/forecast/video/[id].vue").then(m => m.default || m)
  },
  {
    name: "solid-state-data-reports-report-id",
    path: "/solid-state/data-reports/report/:id()",
    meta: _91id_937WdK7kVlCvMeta || {},
    component: () => import("/opt/buildhome/repo/pages/solid-state/data-reports/report/[id].vue").then(m => m.default || m)
  },
  {
    name: "solid-state",
    path: "/solid-state",
    meta: indexmQDcWUWQBFMeta || {},
    component: () => import("/opt/buildhome/repo/pages/solid-state/index.vue").then(m => m.default || m)
  },
  {
    name: "solid-state-market-insights-slug",
    path: "/solid-state/market-insights/:slug()",
    meta: _91slug_93Q9iJZNNVhiMeta || {},
    component: () => import("/opt/buildhome/repo/pages/solid-state/market-insights/[slug].vue").then(m => m.default || m)
  },
  {
    name: "synthetic-graphite-slug",
    path: "/synthetic-graphite/:slug()",
    component: () => import("/opt/buildhome/repo/pages/synthetic-graphite/[slug].vue").then(m => m.default || m)
  },
  {
    name: "synthetic-graphite-data-reports-slug",
    path: "/synthetic-graphite/data-reports/:slug()",
    meta: _91slug_93dMpE1LOwDvMeta || {},
    component: () => import("/opt/buildhome/repo/pages/synthetic-graphite/data-reports/[slug].vue").then(m => m.default || m)
  },
  {
    name: "synthetic-graphite-data-reports-forecast-video-id",
    path: "/synthetic-graphite/data-reports/forecast/video/:id()",
    component: () => import("/opt/buildhome/repo/pages/synthetic-graphite/data-reports/forecast/video/[id].vue").then(m => m.default || m)
  },
  {
    name: "synthetic-graphite-data-reports-price-assessments-report-id",
    path: "/synthetic-graphite/data-reports/price-assessments/report/:id()",
    meta: _91id_93QfD7Tc6LVOMeta || {},
    component: () => import("/opt/buildhome/repo/pages/synthetic-graphite/data-reports/price-assessments/report/[id].vue").then(m => m.default || m)
  },
  {
    name: "synthetic-graphite-data-reports-price-assessments-video-id",
    path: "/synthetic-graphite/data-reports/price-assessments/video/:id()",
    component: () => import("/opt/buildhome/repo/pages/synthetic-graphite/data-reports/price-assessments/video/[id].vue").then(m => m.default || m)
  },
  {
    name: "synthetic-graphite-data-reports-sustainability-video-id",
    path: "/synthetic-graphite/data-reports/sustainability/video/:id()",
    meta: _91id_93Qk8zM6y5dDMeta || {},
    component: () => import("/opt/buildhome/repo/pages/synthetic-graphite/data-reports/sustainability/video/[id].vue").then(m => m.default || m)
  },
  {
    name: "synthetic-graphite",
    path: "/synthetic-graphite",
    meta: indexNhdCvu5I6TMeta || {},
    component: () => import("/opt/buildhome/repo/pages/synthetic-graphite/index.vue").then(m => m.default || m)
  },
  {
    name: "synthetic-graphite-market-insights-slug",
    path: "/synthetic-graphite/market-insights/:slug()",
    meta: _91slug_93FZ8cKa6hAMMeta || {},
    component: () => import("/opt/buildhome/repo/pages/synthetic-graphite/market-insights/[slug].vue").then(m => m.default || m)
  },
  {
    name: "synthetic-graphite-sustainability-slug",
    path: "/synthetic-graphite/sustainability/:slug()",
    meta: _91slug_93mrWTYY1mL5Meta || {},
    component: () => import("/opt/buildhome/repo/pages/synthetic-graphite/sustainability/[slug].vue").then(m => m.default || m)
  },
  {
    name: "synthetic-graphite-synthetic-graphite-prices-slug",
    path: "/synthetic-graphite/synthetic-graphite-prices/:slug()",
    meta: _91slug_93oZyP7IPyVAMeta || {},
    component: () => import("/opt/buildhome/repo/pages/synthetic-graphite/synthetic-graphite-prices/[slug].vue").then(m => m.default || m)
  },
  {
    name: "terms",
    path: "/terms",
    component: () => import("/opt/buildhome/repo/pages/terms.vue").then(m => m.default || m)
  }
]