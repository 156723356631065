// Benchmark Gigafactories America 2022 tailwind colors

const bga2022 = {
  'green-300': '#389276',
  'blue-500': '#283168',
  'purple-300': '#8f68c0',
  'purple-500': '#7e5ab2',
  'purple-800': '#241B40',
}

export default bga2022
