export const state = () => ({
  enable: false,
})

export const mutations = {
  setEnable(state, enable) {
    state.enable = enable
  },
}

export const actions = {
  setEnable({ commit }, enable) {
    commit('setEnable', enable)
  },
}

export const getters = {
  enable: (state) => state.enable,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
