import REDIRECTS from '~/constants/redirects'
import DYNAMIC_REDIRECTS from '~/constants/redirects/dynamicRedirects'

const getDynamicRoute = (path: string): string => {
  const route = DYNAMIC_REDIRECTS.find(({ from }) => from.test(path))
  return route?.to
}

const isMarketPath = (path) => path.startsWith('/market')

const getMarketRedirect = (path, query) => {
  const marketPath = path.replace('/market', '')
  return `${marketPath}/${query}`
}

export default defineNuxtRouteMiddleware((to) => {
  const path = to.path.replace(/\/$/, '')
  const redirectTo = REDIRECTS[path] || getDynamicRoute(path)
  const isExternal = redirectTo && redirectTo.startsWith('https')
  const query = to.query

  if (query && query.tab && isMarketPath(path)) {
    return navigateTo(getMarketRedirect(path, query.tab), { redirectCode: 301 })
  }

  if (!redirectTo) return

  if (isExternal) return navigateTo(redirectTo, { external: true })

  return navigateTo(redirectTo, { redirectCode: 301 })
})
